import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NotificationPopup from "../Global/NotificationPopup";
import { apiPost } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import Loader from "components/Global/Loader";

const CreateServices = (props) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.CreateServices,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Services Successfully!");
        props.cloaseModal();
        props.reLoadData();
        setIsLoading(false);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added");
        console.log(error);
        setIsLoading(false);
        props.cloaseModal();
      }
    );
  };

  return (
    <>
      {/* serviceAmount */}
      <Modal show={props.showModal} backdrop="static">
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="serviceName"
                placeholder="Service Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>service Amount</Form.Label>
              <Form.Control
                required
                type="number"
                name="serviceAmount"
                placeholder="Service Amount"
                min="1"
              />
            </Form.Group>
            <div className="text-right">
              <Button variant="info" onClick={() => props.cloaseModal()}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default CreateServices;
