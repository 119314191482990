import React,{useEffect} from 'react'

const PaymentCancel = () => {
    useEffect(() => {
        // Update the document title using the browser API
        var formValue = localStorage.getItem("data-forms");
        if(formValue){
            localStorage.removeItem("data-forms");
        }
      },[]);
    return (
        <div>
            <div className="page-wrap d-flex flex-row align-items-center">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12 text-center">
                <div className="mb-4 lead">Payment request not completed</div>
                <a href="/" className="btn btn-link">Back to Home</a>
            </div>
        </div>
    </div>
</div>

        </div>
    )
}

export default PaymentCancel
