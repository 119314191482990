import React, { useState } from "react";
import { Card, Container, CardBody, Col, Row, CardHeader } from "reactstrap";
import Nav from "react-bootstrap/Nav";
import UserHeader from "components/Headers/UserHeader.js";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import { apiGetAuth, apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { CheckImageExtension } from "../../auth/DateConverions";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaTrashAlt } from "react-icons/fa";

const TrainPainImages = () => {
  document.title = "Tain the pain";

  const [isLoading, setIsLoading] = useState(false);

  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");

  const [all, setAll] = useState([]);

  React.useEffect(() => {
    getAll();
  }, []);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.AddTrainThePain,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Successfully!");
        setIsLoading(false);
        getAll();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added");
        setIsLoading(false);
        getAll();
      }
    );
  };

  function getAll() {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.TrainThePainAll,
      (res) => {
        console.log(res.result, "pppp");
        setAll(res.result);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  }

  const deleteItem = (item) => {
    setIsLoading(true);
    const data = {
      id: item.id,
    };
    apiPost(
      ENDPOINTS.DeleteTrainThePain,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Successfully!");
        setIsLoading(false);
        getAll();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added");
        setIsLoading(false);
        getAll();
      }
    );
  };

  const checkFilesAcceptImg = (e) => {
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept .svg, .webp, .webp files");
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container className="mt-5" fluid>
        <div>
          <Row className="p-3">
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0"> Pain Management Images</h3>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="mt-0 p-3">
            {all.length < 6 && (
              <Col lg={4} md={4} sm={6} className="mb-4" xs={12}>
                <Card className="meet-our-teams-coulmn-custom">
                  <CardBody className="bodies meet-our-teams-coulmn-custom-inner">
                    <Form onSubmit={handleSubmit}>
                      <div className="upload-btn-wrapper">
                        <Form.Group className="mb-3">
                          <Form.Label>Chose Image</Form.Label>
                          <Form.Control
                            required
                            onChange={(e) => checkFilesAcceptImg(e)}
                            type="file"
                            id="img-input"
                            name="image"
                            placeholder="Chose Image<"
                          />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          upload
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            )}
            {all.length > 0 &&
              all.map((item, index) => (
                <Col lg={4} md={4} sm={6} className="mb-4" key={index} xs={12}>
                  <Card className="meet-our-teams-coulmn-custom">
                    <CardBody className="bodies">
                      <button
                        onClick={() => deleteItem(item)}
                        className="delete-buttib-icons"
                      >
                        <FaTrashAlt />{" "}
                      </button>
                      <div className="dynamic-image-box">
                        <img
                          src={ENDPOINTS.BaseUrl + item.key_value}
                          type="video/mp4"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>

        {all.length < 1 && <div className="no-record"> Record Found</div>}
      </Container>

      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default TrainPainImages;
