
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import React,{useState} from "react";
import { apiGetAuth } from '../../auth/APIRequests';
import { ENDPOINTS } from '../../auth/EndPoints';

const Header = () => {
  const [countUser, setUserCount] = useState(0);
  const [countAppointment, setAppointmentCount] = useState(0);
  const [countTrack, setTrack] = useState(0);
  const [countBookSession, setCountBookSession] = useState(0);

  React.useEffect(() => {
    getAllUserCount();
    getAllAppointmentCount();
    getAllTrackCount();
 },[]);


  function getAllUserCount() {
    apiGetAuth(
      ENDPOINTS.AllPackageUserCount,
      (res) => {
        setUserCount(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function getAllAppointmentCount() {
    apiGetAuth(
      ENDPOINTS.AllAppointmentCount,
      (res) => {
        setAppointmentCount(res.Result.count);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function getAllTrackCount() {
    apiGetAuth(
      ENDPOINTS.AllTrackCount,
      (res) => {
        setTrack(res.Result.count);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  return (
    <>
      <div className="header-change bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Appointments
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                         {countAppointment}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Track
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{countTrack}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="fas fa-headphones"/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Users For Package
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0"> {countUser}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
