import Index from "views/Index.js";
import Register from "views/pages/Register.js";
import Login from "views/pages/Login.js";
import Tracks from "views/pages/Tracks.js";
import Appointments from "views/pages/Appointments.js";
import Services from "views/pages/Services.js";
import UserIndex from "views/pages/UserIndex.js";
import UserAppoinments from "views/pages/UserAppoinments";
import UserTrannings from "views/pages/UserTrannings";
// import UserSessions from "views/pages/UserSessions";
import UserTracks from "views/pages/UserTracks";
import UsersVideos from "views/pages/UsersVideos.js";
import GroupEmailHistory from "views/pages/GroupEmailHistory";
import UserSessionDetails from "views/pages/UserSessionDetails";
import SessionsInvites from "views/pages/SessionsInvites";
import Packages from "views/pages/Packages";
import Users from "views/pages/Users";
import EmailHistory from "views/pages/EmailHistory";
import EntertainmentContent from "views/pages/EntertainmentContent";
import Categories from "views/pages/Categories";
import {
  FaTachometerAlt,
  FaFilm,
  FaImages,
  FaHeadphonesAlt,
  FaBookReader,
  FaServicestack,
  FaHeartbeat,
  FaGuitar,
  FaCertificate,
  FaDungeon,
  FaUsers,
  FaEnvira,
  FaHandsHelping,
  FaClock,
  FaSlidersH,
  FaBlind,
  FaUndoAlt,
  FaCannabis,
  FaGitter,
} from "react-icons/fa";

var routes = [
  // admin
  {
    path: "/index",
    name: "Library",
    icon: <FaTachometerAlt />,
    component: Index,
    layout: "/admin",
  },
  {
    path: "/invites",
    name: "Session invites",
    icon: <FaCertificate />,
    component: SessionsInvites,
    layout: "/admin",
  },
  // {
  //   path: "/train-pain",
  //   name: "Train The Pain",
  //   icon: <FaBlind />,
  //   component: TrainPainImages,
  //   layout: "/admin",
  // },
  {
    path: "/all-categories",
    name: "services",
    icon: <FaCannabis />,
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/entertainment-content",
    name: "Content",
    icon: <FaCannabis />,
    component: EntertainmentContent,
    layout: "/admin",
  },
  {
    path: "/history",
    name: "Session invites history",
    icon: <FaClock />,
    component: EmailHistory,
    layout: "/admin",
  },
  {
    path: "/group-details/:id",
    name: "Group Detail",
    icon: <FaDungeon />,
    component: GroupEmailHistory,
    layout: "/admin",
  },
  {
    path: "/package/:id",
    name: "Package",
    icon: <FaEnvira />,
    component: Packages,
    layout: "/admin",
  },

  {
    path: "/users",
    name: "User",
    icon: <FaUsers />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/appointments",
    name: "Appointments",
    icon: <FaBookReader />,
    component: Appointments,
    layout: "/admin",
  },

  // {
  //   path: "/health-and-beauty",
  //   name: "Health And Beauty",
  //   icon: <FaHeartbeat />,
  //   component: HealthAndBeautyAdmin,
  //   layout: "/admin",
  // },

  {
    path: "/services",
    name: "Spa services",
    icon: <FaServicestack />,
    component: Services,
    layout: "/admin",
  },
  {
    path: "/tracks",
    name: "Tracks",
    icon: <FaGuitar />,
    component: Tracks,
    layout: "/admin",
  },
  // user
  {
    path: "/index",
    name: "Library",
    icon: <FaFilm />,
    component: UsersVideos,
    layout: "/user",
  },
  {
    path: "/package",
    name: "Package",
    icon: <FaHandsHelping />,
    component: UserTrannings,
    layout: "/user",
  },
  {
    path: "/session-details/:id",
    name: "Session Detail",
    icon: <FaDungeon />,
    component: UserSessionDetails,
    layout: "/user",
  },
  {
    path: "/appointment",
    name: "Appointments",
    icon: <FaBookReader />,
    component: UserAppoinments,
    layout: "/user",
  },
  // {
  //   path: "/sessions",
  //   name: "Sessions",
  //   icon:  <FaCertificate/>,
  //   component: UserSessions,
  //   layout: "/user",
  // },
  {
    path: "/tracks",
    name: "Tracks",
    icon: <FaHeadphonesAlt />,
    component: UserTracks,
    layout: "/user",
  },
  // {
  //   path: "/event-photos",
  //   name: "Events Photos",
  //   icon: <FaImages />,
  //   component: EventsPhotos,
  //   layout: "/admin",
  // },
  // auth
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    component: Register,
    layout: "/auth",
  },
];

export default routes;
