
// reactstrap components
import React,{useState} from "react";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { apiGetAuth } from '../../auth/APIRequests';
import { ENDPOINTS } from '../../auth/EndPoints';
const UserHeader = () => {
  const [count, setCount] = useState(0);
  const [countTracl, setCountTrack] = useState(0);
  const [countSession, setCountSession] = useState(0);

  React.useEffect(() => {
    getAllCountAppointments();
    getAllUserTracksCount();
    getAllCountSession()
 },[]);

 function getAllCountAppointments() {
  apiGetAuth(
    ENDPOINTS.UserAppointmentsCount,
    (res) => {
      setCount(res.Result);
    },
    (error) => {
      console.log(error);
    }
  );
}
 function getAllCountSession() {
  apiGetAuth(
    ENDPOINTS.UserBuyCategoryLibrarie,
    (res) => {
      setCountSession(res.Result.count);
    },
    (error) => {
      console.log(error);
    }
  );
}
 function getAllUserTracksCount() {
  apiGetAuth(
    ENDPOINTS.UserTracksCount,
    (res) => {
      setCountTrack(res.Result.count);
    },
    (error) => {
      console.log(error);
    }
  );
}
  return (
    <>
      <div className="header-change bg-gradient-theme pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Appointment
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {count}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                         Bought Video categories 
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{countSession}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tracks
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{countTracl}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                        <i className="fas fa-music" />
                  
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
