import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "components/Front/components/Header/Header";
import Modal from "react-bootstrap/Modal";
import CoachingOnline from "./../../components/Global/CoachingOnline";
import { FaTimes } from "react-icons/fa";
import Footer from "components/Front/components/Footer/Footer";
import imageNotFound from "../../assets/images/image-not-found.webp";
import { ENDPOINTS } from "../../auth/EndPoints";
import { apiGet } from "../../auth/APIRequests";

const ServiceContent = () => {
  document.title = "Service";
  const [allCat, setAllCat] = useState([]);
  const [selectedServices, setSelectedServices] = useState({
    category_id: "",
    category_name: "",
  });
  React.useEffect(() => {
    getAll();
  }, []); //array
  const [showOnlineCoaching, setShowOnlineCoaching] = useState(false);
  function getAll() {
    apiGet(
      ENDPOINTS.getAllCategory,
      (res) => {
        console.log(res.result, "res.result");
        setAllCat(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const selectValues = (item) => {
    setShowOnlineCoaching(true);
    setSelectedServices({
      category_id: item.id,
      category_name: item.name,
    });
  };
  return (
    <>
      <Header />
      <Container className="aboutus-start pb-5">
        <div className="content-dynamic">
          {allCat.length > 0 &&
            allCat .filter(x=>x.status === true)
            .map((item, index) => (
              <Row className="content-dynamic-row no-gutters">
                <Col lg={6} md={12}>
                  <div className="service-box-img">
                    <img
                      src={
                        item?.media
                          ? ENDPOINTS.BaseUrl + item?.media
                          : imageNotFound
                      }
                      className=""
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col lg={6} md={12} className="my-auto">
                  <div className="about-us-texts mt-5">
                    <h4 className="h4-poppine-bold">
                      {item?.name} 
                    </h4>
                    <p>
                      {item?.description
                        ? item?.description
                        : "No description available"}{" "}
                    </p>
                    <button
                      onClick={() => selectValues(item)}
                      className="read-more-read no-hover"
                    >
                      Get Started
                    </button>
                  </div>
                </Col>
              </Row>
            ))}
          {allCat.length === 0 && (
            <h3 className="text-center">No Record Found</h3>
          )}
        </div>
      </Container>
      <Footer className="mt-5" />

      <Modal className="online-coaching-modal" show={showOnlineCoaching}>
        <Modal.Body>
          <button
            onClick={() => setShowOnlineCoaching(false)}
            className="close-btn-modal"
          >
            <FaTimes />
          </button>

          <CoachingOnline selectedServicesPro={selectedServices} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ServiceContent;
