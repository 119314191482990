import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css";
import "aos/dist/aos.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-jinke-music-player/assets/index.css";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import UserLayout from "layouts/User";
import Lending from "./views/pages/Lending";
import Home from "./views/pages/Home";
import AboutUs from "./views/pages/AboutUs";
import SongList from "./views/pages/SongList";
import HealthAndBeauty from "./views/pages/HealthAndBeauty";
import VideosSessions from "./views/pages/VideosSessions";
import ThankYou from "./views/pages/ThankYou";
import PaymentCancel from "./views/pages/PaymentCancel";
import ServiceContent from "./views/pages/ServiceContent";
import Notfound from "./views/pages/Notfound";
import "video-react/dist/video-react.css";
import "./assets/css/app.css";
import "./assets/css/style.css";
const role = localStorage.getItem("role");

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {role === "1" && (
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      )}
      {role === "2" && (
        <Route path="/user" render={(props) => <UserLayout {...props} />} />
      )}
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route exact path="/">
        <Lending />
      </Route>
      <Route path="/entertainment">
        <Home />
      </Route>
      <Route path="/about">
        <AboutUs />
      </Route>
      <Route path="/track-list">
        <SongList />
      </Route>
      <Route path="/library">
        <VideosSessions />
      </Route>
      <Route path="/services">
        <ServiceContent />
      </Route>
      <Route path="/health-and-beauty">
        <HealthAndBeauty />
      </Route>
      <Route path="/paypal-success">
        <ThankYou />
      </Route>
      <Route path="/paypal-cancel">
        <PaymentCancel />
      </Route>
      <Route component={Notfound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
