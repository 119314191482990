import React, { useRef } from "react";
import Slider from "react-slick";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const HomeSlider = () => {
  const [all, setAll] = React.useState([]);
  React.useEffect(() => {
    getAll();
  }, []);
  function getAll() {
    apiGetAuth(
      ENDPOINTS.EntertainmentAll,
      (res) => {
        setAll(res.result);
        console.log(res.result, "pppp");
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const slickPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 10000,
  };
  return (
    <div className="position-relative">
      {all.length > 0 && (
        <>
          <Slider ref={sliderRef} {...settings}>
            {all.map((item, index) => (
              <div className="customs-trainers customs-trainers-enter">
                <img src={ENDPOINTS.BaseUrl + item.key_value} alt="" />
              </div>
            ))}
          </Slider>
          <div className="clubs-slide-btns-box-end">
            <button className="clubs-slide-btns" onClick={slickPrev}>
              <FaArrowLeft />
            </button>
            <button className="clubs-slide-btns" onClick={gotoNext}>
              <FaArrowRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeSlider;
