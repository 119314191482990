import React, { useRef } from "react";
import Slider from "react-slick";
import Slide2 from "../../assets/images/slide-2.webp";
import Slide3 from "../../assets/images/slide-3.webp";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const ClubSlider = () => {
  const [all, setAll] = React.useState([]);
  React.useEffect(() => {
    getAll();
  }, []);
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const slickPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  function getAll() {
    apiGetAuth(
      ENDPOINTS.HomeSliderAll,
      (res) => {
        setAll(res.result);
        console.log(res.result, "pppp");
      },
      (error) => {
        console.log(error);
      }
    );
  }
  return (
    <div className="position-relative">
      {all.length > 0 && (
        <>
          <Slider className="home-sliders" ref={sliderRef} {...settings}>
            {/* style ={ { backgroundImage: "url('${ENDPOINTS.BaseUrl + item.key_value}')" } } */}
            {all.map((item, index) => (
              <div className="customs-trainers" key={index}>
                <img src={ENDPOINTS.BaseUrl + item.key_value} alt="" />

                <div className="slide-for-text">
                  <div className="slide-for-text-customs">
                    <Fade top>
                      <h1 className="text-white prenium-heading">
                        Personal Trainer
                      </h1>
                      <p className="text-white">
                        Wake up. It’s time to change{" "}
                        <FaArrowRight className="ms-2" />
                      </p>
                      <button
                        onClick={() => {
                          window.location.href = "/auth/register";
                        }}
                      >
                        Join Us{" "}
                      </button>
                    </Fade>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="clubs-slide-btns-box">
            <button className="clubs-slide-btns" onClick={slickPrev}>
              <FaArrowLeft />
            </button>
            <button className="clubs-slide-btns" onClick={gotoNext}>
              <FaArrowRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ClubSlider;
