import React, { useState } from "react";
import ClubSlider from "../../components/Global/ClubSlider";
import ClubClasses from "../../components/Global/ClubClasses";
import WelcomeLending from "../../components/Global/WelcomeLending";
import OurClassesLending from "../../components/Global/OurClassesLending";
import MeetTeam from "../../components/Global/MeetTeam";
import ContactUs from "../../components/Global/ContactUs";
import QuickContactsFooter from "components/Front/components/Footer/QuickContactsFooter";
import Header from "components/Front/components/Header/Header";
import song2 from "../../assets/audio/instrumental/BredWinnerFit-Instrumental.mp3";
import imgs from "../../assets/images/songs.webp";
import { apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import songsIcon from "../../assets/images/songs.webp";
import Loader from "components/Global/Loader";

const Lending = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [audioList1, setAll] = useState([
    {
      name: "Bred Winner Fit- Instrumental",
      singer: "Bred Winner Fit- Instrumental",
      cover: imgs,
      musicSrc: song2,
    },
  ]);
  const options = {
    // audio lists model
    audioLists: audioList1,
    autoPlay: true,
    toggleMode: true,
    mode: "full",
    remove: false,
    showThemeSwitch: false,
    showDownload: false,
    showPlayMode: false,
    showLyric: false,
    responsive: true,
    showPlay: true,
    showReload: false,
    preload: false,
    glassBg: true,
    drag: false,
    seeked: false,
    showMiniModeCover: false,
    showMiniProcessBar: false,
    showProgressLoadBar: false,
    showMediaSession: false,
  };

  React.useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    setIsLoading(true);
    apiGet(
      ENDPOINTS.AllHomeTracks,
      (res) => {
        if (res.Result.length > 0) {
          res.Result.forEach((item) => {
            setAll([
              {
                name: item.trackName,
                singer: "song",
                cover: songsIcon,
                musicSrc: ENDPOINTS.BaseUrl + item.trackSource,
              },
            ]);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  document.title = "Home";
  return (
    <div className="logo-back">
      <Header />
      <ClubSlider />
      <ClubClasses />
      <WelcomeLending />
      <OurClassesLending className="mt-5" />
      <MeetTeam />
      <ContactUs />
      <QuickContactsFooter />

      {isLoading && <Loader />}
    </div>
  );
};

export default Lending;
