import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import NotificationPopup from "../Global/NotificationPopup";
import { apiDelete } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import Loader from "components/Global/Loader";

const DeleteServices = (props) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const deleteEvent = () => {
    setIsLoading(true);
    apiDelete(
      ENDPOINTS.DeleteServices + props.deletePro.id,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Delete Services Successfully!");
        props.closeDeleteModalPro();
        props.reLoadData();
        setIsLoading(false);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("There is an error");
        console.log(error);
        setIsLoading(false);
        props.closeDeleteModalPro();
      }
    );
  };
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={props.showDeleteModalPro}
        toggle={() => props.closeDeleteModalPro()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.closeDeleteModalPro()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">You should read this!</h4>
            <p>
              You want to delete this <b>{props.deletePro.serviceName} </b>{" "}
              Photo ?
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            onClick={() => deleteEvent()}
            type="button"
          >
            Yes
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.closeDeleteModalPro()}
          >
            Close
          </Button>
        </div>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default DeleteServices;
