import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NotificationPopup from "../Global/NotificationPopup";
import { apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import {
  CheckImageExtension,
  ConvertDefaultHtmlCurremt,
  MaxDateValidation,
} from "../../auth/DateConverions";
import Loader from "components/Global/Loader";

// import { isImage } from './../../auth/DateConverions';

const CreateUpcommingEvent = (props) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.AddUpcommingEvents,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Track Successfully!");
        props.cloaseModal();
        props.reLoadData();
        setIsLoading(false);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("There is an error");
        console.log(error);
        props.cloaseModal();
        setIsLoading(false);
      }
    );
  };

  const checkFilesAcceptImg = (e) => {
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept jpg,png,svg  files");
    }
  };

  return (
    <>
      <Modal show={props.showModal} backdrop="static">
        <Modal.Body closeButton>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Event title</Form.Label>
              <Form.Control
                required
                type="text"
                name="title"
                placeholder="Class Title"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Event date</Form.Label>
              <Form.Control
                required
                type="date"
                name="date"
                min={MaxDateValidation()}
                placeholder="Class Title"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Event content</Form.Label>
              <textarea
                className="form-control"
                required
                type="text"
                name="content"
                placeholder="Class Title"
              ></textarea>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Chose Image</Form.Label>
              <Form.Control
                required
                type="file"
                name="image"
                id="img-input"
                onChange={(e) => checkFilesAcceptImg(e)}
                placeholder="Client Review"
              />
            </Form.Group>
            <div className="text-right">
              <Button variant="info" onClick={() => props.cloaseModal()}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Add Event
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default CreateUpcommingEvent;
