import React, { useState, useEffect, useCallback, useMemo } from "react";
import Header from "components/Headers/Header.js";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaLink } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { apiGetAuth, apiGet, apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const SessionsInvites = () => {
  document.title = "Invite";
  const [dataSelected, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [all, setAll] = useState([]);
  const [groupId, setGroupId] = useState(0);
  const [profileCategories, setProfileCategories] = useState([]);
  const [packagesCategories, setPackagesCategories] = useState([]);
  const [packageSelected, setPackageSelected] = useState(true);

  const [selectedPackageId, setSelectedPackageId] = useState(true);

  React.useEffect(() => {
    getAllCategry();
  }, []);

  const getAllCategry = async () => {
    apiGet(
      ENDPOINTS.getAllCategory,
      (res) => {
        setProfileCategories(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAll = (packageId) => {
    apiGetAuth(
      ENDPOINTS.PackageUsers + `group_id=${groupId}&package_id=${packageId}`,
      (res) => {
        console.log(res.Result);
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const groupChange = (id) => {
    if (id !== "0") {
      setPackageSelected(false);
      apiGet(
        ENDPOINTS.getAllCategory,
        (res) => {
          setGroupId(id);
          getAllPackagesBYCategry(id);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    if (id === "0") {
      setPackagesCategories([]);
      setPackageSelected(true);
    }
  };
  const packageChange = async (id) => {
    setSelectedPackageId(id);
    getAll(id);
  };

  const getAllPackagesBYCategry = (id) => {
    apiGetAuth(
      ENDPOINTS.getPackagesByCategory + id,
      (res) => {
        setPackagesCategories(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const columns = useMemo(() => [
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
  ]);

  const handleSelectedRow = async (item) => {
    setSelectedData(item.selectedRows);
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();

    const data = {
      zoon_link: document.querySelector("input[name=zoon_link]").value,
      date_time: document.querySelector("input[name=date_time]").value,
      descriptions: document.querySelector("textarea[name=descriptions]").value,
      users: dataSelected,
      package_id: selectedPackageId,
    };

    apiPost(
      ENDPOINTS.SendsInvites,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("send successfully!");
        setIsLoading(false);
        setShowEmailModal(false);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="dataTable-customes">
        <h3 className="mb-0">Invite User </h3>
        <div className="row">
          <div className="col-md-4 mb-4 sort-by-filters-invites">
            <label>Sort by Group</label>
            <select
              className="form-control"
              name="gender"
              onChange={(e) => groupChange(e.target.value)}
            >
              <option value="0">select group</option>

              {profileCategories.length > 0 &&
                profileCategories
                  .map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
            </select>
          </div>
          <div className="col-md-4 mb-4 sort-by-filters-invites">
            <label>Sort by package</label>
            <select
              className="form-control"
              name="gender"
              onChange={(e) => packageChange(e.target.value)}
              disabled={packageSelected}
            >
              <option value="0">select package</option>
              {packagesCategories.length > 0 &&
                packagesCategories.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          {dataSelected.length > 0 && (
            <div className="col-md-4 mb-4 invites-btns">
              <button
                className="btn btn-danger"
                onClick={() => setShowEmailModal(true)}
              >
                invites
              </button>
            </div>
          )}
        </div>
        <DataTable
          columns={columns}
          data={all}
          paginationServer
          selectableRows
          onSelectedRowsChange={handleSelectedRow}
        />
      </div>
      <Modal show={showEmailModal} backdrop="static">
        <Modal.Body closeButton>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="font-14 ml-2">Zoom Link</Form.Label>
              <div className="d-flex">
                <span className="link-zoom">
                  <FaLink />
                </span>
                <Form.Control
                  required
                  type="text"
                  name="zoon_link"
                  placeholder="Zoom Link"
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 m-2">
              <Form.Label className="font-14"> Select Date and time</Form.Label>
              <Form.Control
                className="font-14"
                required
                type="datetime-local"
                name="date_time"
                placeholder="Select Date and time"
              />
            </Form.Group>

            <Form.Group className="mb-3 m-2">
              <Form.Label className="font-14">Description</Form.Label>
              <textarea
                className="description-of-invites font-14"
                name="descriptions"
                placeholder="description about session"
              ></textarea>
            </Form.Group>
            <div className="text-right">
              <Button variant="info" onClick={() => setShowEmailModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Send Email
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default SessionsInvites;
