import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NotificationPopup from "./NotificationPopup";
import Loader from "./Loader";
import { apiPostWithoutAuth } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";

import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
const ContactUs = () => {
    const [showSnack, setSnack] = useState(false);
    const [snackColor, setSnackColor] = useState("bg-primary");
    const [snackMsg, setSnackMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const data = new FormData(event.target);
        apiPostWithoutAuth(
          ENDPOINTS.ContactUsForm,
          data,
          (res) => {
            setSnack(true);
            setSnackColor("bg-success");
            setSnackMsg("email sent Successfully!");
            setIsLoading(false);
          },
          (error) => {
            setSnack(true);
            setSnackColor("bg-danger");
            setSnackMsg("there is an error");
            setIsLoading(false);
 
          }
        );
      };
    
    return (
        <div>
            <Container fluid className="mt-5 p-0">
                <div className="back-contact-us">
                    <Row className="mb-4 no-gutters">
                        <Col sm={12} md={12} lg={6} className="position-relative">
                            <div className="contact-us-box">
                                <h2>Contact Us</h2>
                                <hr />
                                <form onSubmit={handleSubmit}>
                                    <input type="text" required name="subject" className="contact-us-inpus" placeholder="Subject" />
                                    <input type="Email" required name="email" className="contact-us-inpus" placeholder="Email" />
                                    <textarea  name="message" required className="contact-us-inpus-textarea" placeholder="Write Your Message Here..." rows="4">

                                    </textarea>
                                    <button type="submit" className="contact-submit">Submit</button>
                                    <div className="contact-us-info mt-3">
                                        <p className="mn-3  emsila-1 text-white"><a href="mailto:bredwinnerfitness@gmail.com">bredwinnerfitness@gmail.com</a></p>

                                        <span className="contact-us-line" />
                                        <p className='email-2'> <a href="tel:513-253-1350">513-253-1350</a></p>
                                    </div>
                                    <div className="contact-us-link mt-3">
                                        <a href="https://www.facebook.com/BredWinnerFitness/"><FaFacebookF /></a>
                                        <a href="http://linkedin.com/in/coach-taa-336792171"><FaLinkedinIn /></a>
                                        <a href="#"><FaTwitter /></a>
                                    </div>


                                </form>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6} className="text-center position-relative">
                            <div className="contact-us-box-img">

                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />

      {isLoading && <Loader />}
        </div>
    )
}

export default ContactUs
