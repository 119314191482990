import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "./../../../../assets/images/logo.png";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

const Header = () => {
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.clear();
    window.location.href = "/";
  };
  const role = localStorage.getItem("role");

  return (
    <>
      <Navbar bg="white" className="nav-header nav-header-front" expand="lg">
        <Container fluid className="header">
          <Navbar.Brand href="/">
            <img src={logo} className="logo-header" alt="img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end basic-navbar-nav-info"
          >
            <Nav className="navs-item nav-header-item-front">
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/">
                  Home
                </Link>
              </Nav>
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/about">
                  About Us
                </Link>
              </Nav>
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/services">
                  Services
                </Link>
              </Nav>
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/library">
                  Library
                </Link>
              </Nav>

              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/entertainment">
                  Entertainment
                </Link>
              </Nav>
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/track-list">
                  Tracks
                </Link>
              </Nav>
              <Nav className="navs-item">
                <Link className="nav-link navs-item" to="/health-and-beauty">
                  Health And Beauty
                </Link>
              </Nav>
              {parseInt(role) === 2 && (
                <>
                  <Nav className="navs-item">
                    <Link className="nav-link navs-item" to="/user/index">
                      Dashboard
                    </Link>
                  </Nav>
                </>
              )}
              {parseInt(role) === 1 && (
                <>
                  <Nav className="navs-item">
                    <Link className="nav-link navs-item" to="/admin/index">
                      Dashboard
                    </Link>
                  </Nav>
                </>
              )}

              {parseInt(role) === 1 ? (
                <button
                  onClick={logout}
                  className="bg-light-green navs-item contact-btn no-strss"
                >
                  Logout
                </button>
              ) : parseInt(role) === 2 ? (
                <button
                  onClick={logout}
                  className="bg-light-green navs-item contact-btn no-strss"
                >
                  Logout
                </button>
              ) : (
                <Nav className="">
                  <Link
                    className="nav-link bg-light-green navs-item contact-btn"
                    to="/auth/login"
                  >
                    Login
                  </Link>
                </Nav>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
