import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logos from "./../../../../assets/images/footer-logo.webp";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import Fade from "react-reveal/Fade";

const QuickContactsFooter = () => {
  const [activeClassIndext, setIndex] = useState("1");

  const setActiveClass = (e) => {
    setIndex(e.target.value);
  };
  return (
    <div className="quick-footer-back">
      <Fade top>
        <Container fluid className="pt-0">
          <Row>
            <Col md={12} lg={5} className="position-relative ">
              <div className="footer-logos">
                <img src={Logos} alt="sdad" />
              </div>
            </Col>
            <Col md={12} lg={7} className="position-relative ">
              <Row>
                <Col md={12} lg={5} className="position-relative ">
                  <div className="quick-Contacts-column">
                    <h3>Contact Us</h3>
                    <p>P.O. Box 18228 Cincinnati, Ohio 45218</p>
                    <p className="email-2">
                      {" "}
                      <a href="tel:513-253-1350">
                        <span>
                          <FaPhoneAlt />
                        </span>{" "}
                        513-253-1350
                      </a>
                    </p>
                    <p className="email-2">
                      <a href="mailto:bredwinnerfitness@gmail.com">
                        <span>
                          <FaEnvelope />
                        </span>{" "}
                        bredwinnerfitness@gmail.com
                      </a>{" "}
                    </p>
                    <div className="socials-links-footers">
                      <a href="#">
                        <FaInstagram />
                      </a>
                      <a href="http://linkedin.com/in/coach-taa-336792171">
                        <FaLinkedinIn />
                      </a>
                      <a href="https://www.facebook.com/BredWinnerFitness/">
                        <FaFacebookF />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={7} className="position-relative ">
                  <div className="popular-tags-btn">
                    <button
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      className="tag-btn tag-btn-active"
                      value="1"
                    >
                      Home
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/entertainment";
                      }}
                      className="tag-btn tag-btn-active"
                      value="2"
                    >
                      Entertainment
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/about";
                      }}
                      className="tag-btn tag-btn-active"
                      value="3"
                    >
                      About Us
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/track-list";
                      }}
                      className="tag-btn tag-btn-active"
                      value="4"
                    >
                      Tracks
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/services";
                      }}
                      className="tag-btn tag-btn-active"
                      value="5"
                    >
                      Services
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/services";
                      }}
                      className="tag-btn  tag-btn-active   "
                      value="6"
                    >
                      Online Coaching
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/health-and-beauty";
                      }}
                      className="tag-btn tag-btn-active"
                      value="7"
                    >
                      Health And Beauty
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fade>
    </div>
  );
};

export default QuickContactsFooter;
