import axios from 'axios';

function getCommonHeaders(h) {
  let headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  };
  const token = localStorage.getItem('accessToken');
  if (token) headers.Authorization = `Bearer ${token}`;
  if (h) headers = { ...h, ...headers };
  return headers;
}


export const apiGet = async (url, onSuccess, onFailure, headers) => {
  await axios
    .get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (onSuccess) onSuccess(response.data);
    })
    .catch((error) => {
      // if (error.StatusCode === 401) {
      //   // localStorage.clear();
      //   // localStorage.setItem('tokenExpire', 'true');
      //   // window.location.href = '/?tokenExpire=true';
      // }
      if (onFailure) onFailure(error);
    });
};
export const apiGetAuth = async (url, onSuccess, onFailure, headers) => {
  await axios
    .get(url, {
      headers: getCommonHeaders(headers),
    })
    .then((response) => {
      if (onSuccess) onSuccess(response.data);

    })
    .catch((error) => {
      if (error.response.status === 401) {
    
        localStorage.clear();
        window.location.href = '/';
      }
      if (onFailure) onFailure(error);
    });
};



export const apiPost =  async (url, body, onSuccess, onFailure) => {
 axios
    .post(url, body, {
      headers: getCommonHeaders()
    })
    .then((response) => {
      if (onSuccess) onSuccess(response.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      if (onFailure) onFailure(error);
    });
};

export const apiPostWithoutAuth =  async (url, body, onSuccess, onFailure) => {
  axios
     .post(url, body, {
        headers: {
          headers: {"Access-Control-Allow-Origin": "*"}
      },
     })
     .then((response) => {
       if (onSuccess) onSuccess(response.data);
     })
     .catch((error) => {
       if (error.response.status === 401) {
         localStorage.clear();
         window.location.href = '/';
       }
       if (onFailure) onFailure(error);
     });
 };

export const apiPut = async (url, body, onSuccess, onFailure) => {
  await axios
    .put(url, body, {
      headers: getCommonHeaders()
    })
    .then((response) => {
      if (onSuccess) onSuccess(response.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      if (onFailure) onFailure(error);
    });
};
export const apiDelete = async (url, onSuccess, onFailure) => {
  await axios
    .delete(url, {
       headers: getCommonHeaders()
    })
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      onFailure(error);
    });
};