import React from 'react'
import { FaAngleRight } from "react-icons/fa";


const ArrowRight = ({colors}) => {
  return (
    <div className="arrow-right-com">
      <span class="arrow-left arrow-sliding-right delay1" style={{ color: colors }}><FaAngleRight /></span>  
      <span class="arrow-left arrow-sliding-right delay2" style={{ color: colors }}><FaAngleRight /></span>  
      <span class="arrow-left arrow-sliding-right delay3" style={{ color: colors }}><FaAngleRight /></span>  
   
  </div>
  )
}

export default ArrowRight