import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import M1 from "../../assets/images/m-1.webp";
import M2 from "../../assets/images/m-2.webp";
import M3 from "../../assets/images/m-3.webp";
import M4 from "../../assets/images/sss-1.webp";
import Fade from "react-reveal/Fade";
import { FaTimes } from "react-icons/fa";
import Online from "../../assets/images/on-line.webp";

const ClubClasses = () => {
  const [trainPain, settrainPain] = useState(false);
  const [personalTraining, setpersonalTraining] = useState(false);
  const [groupTraining, settgroupTraining] = useState(false);
  const [onlineCoching, settOnlineCoching] = useState(false);
  return (
    <div className=" mb-5 overflow-containers">
      <Container>
        <Row>
          <Col md={3} sm={12} className="mb-3 position-relative">
            <div className="club-per-month-box-ani">
              <Fade top>
                <div
                  className="club-per-month-box"
                  onClick={() => setpersonalTraining(true)}
                >
                  <img src={M4} alt="dsadsa" />
                  <div className="club-classes-text">
                    <h4 className="text-white">Personal Training</h4>
                    {/* <p className="text-white">Trained Achieve Your Goals</p> */}
                    {/* <button>$60/M</button> */}
                  </div>
                </div>
                <div className="modal-sign-box">
                  <button  onClick={() => { window.location.href = "/auth/register" }} className="modal-sign-btn">Sign up </button>
                  <button  onClick={() => setpersonalTraining(true)} className="modal-sign-btn"  >More info</button>
                </div>
              </Fade>
            </div>
          </Col>
          <Col md={3} sm={12} className="mb-3 position-relative">
            <div
              className="club-per-month-box-ani"
              onClick={() => settrainPain(true)}
            >
              <Fade bottom>
                <div className="club-per-month-box">
                  <img src={M3} alt="dsadsa" />
                  <div className="club-classes-text">
                    <h4 className="text-white">Pain Management </h4>
                    {/* <p className="text-white">Trained under best trainers</p> */}
                    {/* <button>$120/M</button> */}
                  </div>
                </div>
                     <div className="modal-sign-box">
                  <button  onClick={() => { window.location.href = "/auth/register" }} className="modal-sign-btn">Sign up </button>
                  <button  onClick={() => settrainPain(true)} className="modal-sign-btn"  >More info</button>
                </div>
              </Fade>
            </div>
          </Col>
          <Col md={3} sm={12} className="mb-3 position-relative">
            <div className="club-per-month-box-ani">
              <Fade top>
                <div
                  className="club-per-month-box"
                  onClick={() => settgroupTraining(true)}
                >
                  <img src={M1} alt="dsadsa" />
                  <div className="club-classes-text">
                    <h4 className="text-white">Group Fitness</h4>
                    {/* <p className="text-white">Trained Day and Night</p> */}
                    {/* <button>$200/M</button> */}
                  </div>
                </div>
                <div className="modal-sign-box">
                  <button  onClick={() => { window.location.href = "/auth/register" }} className="modal-sign-btn">Sign up </button>
                  <button  onClick={() => settgroupTraining(true)} className="modal-sign-btn"  >More info</button>
                </div>
              </Fade>
            </div>
          </Col>
          <Col md={3} sm={12} className="mb-3 position-relative">
            <div className="club-per-month-box-ani">
              <Fade top>
                <div
                  className="club-per-month-box"
                  onClick={() => settOnlineCoching(true)}
                >
                  <img src={Online} alt="dsadsa" />
                  <div className="club-classes-text">
                    <h4 className="text-white">Online Coaching</h4>
                    {/* <p className="text-white">Trained Day and Night</p> */}
                    {/* <button>$200/M</button> */}
                  </div>
                </div>
                <div className="modal-sign-box">
                  <button  onClick={() => { window.location.href = "/auth/register" }} className="modal-sign-btn">Sign up </button>
                  <button  onClick={() => settOnlineCoching(true)} className="modal-sign-btn"  >More info</button>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      {/*personal tranning*/}
      <Modal
        show={personalTraining}
        backdrop="static"
        scrollable={true}
        size="lg"
      >
        <button
          onClick={() => setpersonalTraining(false)}
          className="traning-modals-head-buttons"
        >
          <FaTimes />
        </button>
        {/* Personal Training Programs */}
        <Modal.Body>
          <div className="traning-modals-head">
            <h3 className="mb-0">Specialized Personal Training Programs</h3>
            <h5 className="">Online Instruction Available</h5>
          </div>
          <div className="traning-modals position-relative">
            <div className="tranning-modal-content">
              <h5 className="mt-4">Weight Management--(In-Person & Online)</h5>
              <p>
                Millions of people struggle with weight management (not to be
                confused with weight loss) issues for a plethora of reasons. By
                definition, weight management is simply attaining and
                maintaining a specific weight. Well, who determines that weight?
                You do! At Bred Winner Fitness Certified Personal Trainers will
                conduct a complete Fitness Assessment. The results of your
                personal assessment will serve as the cornerstone for which the
                weight management routines will be designed.{" "}
              </p>

              <p>
                So whether your the type who wants to eat whatever you like, or
                the kind who googles every ingredient and counts every calorie;
                Bred Winner Fitness has a program for you! Bred Winner Trainers
                will utilize relative principles of Sports Medicine and Exercise
                Physiology along with your individual assessment to design a
                specialized weight management program to help you meet and
                maintain your weight management goals. When combined with the
                Bred 2 Win Mentality that Bred Winner Trainers will help you
                discover, nourish and grow, personal success is guaranteed!{" "}
              </p>
              <h5>Transitions Training Series--(In-Person & Online)</h5>
              <p>
                At Bred Winner Fitness, Certified Personal Trainers use relative
                principles of Sports Medicine and Exercise Physiology, along
                with the results from your Individual Assessment, to create
                custom programs. These programs include personalized routines
                designed to target specified areas, helping our clients look and
                feel their absolute best.{" "}
              </p>
              <p>
                A transition is a process of change. To go from unfit to fit, or
                less fit to more fit requires a physical change. To maintain
                that physical change, requires a mental change. Bred Winner
                Trainers understand that training the mind to prepare for
                exercise, is itself, an exercise. One that is equally as
                important and beneficial as warming up before actually
                performing a physical exercise. So in addition to creating and
                designing efficient and effective custom routines, we also help
                our clients discover, nourish and grow that Bred 2 Win Mentality
                that will guarantee their personal success!
              </p>
              <h5>ThoroughBred Fitness</h5>
              <p>
                **High intensity, fast paced, low impact, calisthenic cardio
                vascular exercise routines, individually designed for clients of
                all fitness levels to help them achieve Optimal Fitness!!!**
                Repeat that 3X
              </p>
              <p>
                A ThoroughBred is “a person of uncommon strength and endurance”.
                This training series focuses on total body fitness. Ideal for
                former athletes or individuals seeking a much more active
                lifestyle. Bred Winner Trainers use the results from the initial
                assessment along with tried and tested scientific principles to
                design custom routines that build body composition, muscular
                composition, muscular strength, muscular endurance,
                cardiorespiratory fitness, flexibility and agility.{" "}
              </p>
              <p>
                These are specialized routines designed to be quick & effective,
                unique & fun, but extremely CHALLENGING. After the Initial
                Assessment, Bred Winner Trainers will use Industry proven, tried
                and tested scientific principles to create and design routines
                that maximize each movement to achieve the maximum results
                naturally, in short amounts of time. These routines are sure to
                challenge even the most highly trained athletes. And 100%
                guaranteed!
              </p>
              <a href="/auth/register" className="read-more-read no-hover">
                Sign Up
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Train The Pain!(train the pain*/}
      <Modal show={trainPain} backdrop="static" scrollable={true} size="lg">
        <button
          onClick={() => settrainPain(false)}
          className="traning-modals-head-buttons"
        >
          <FaTimes />
        </button>

        <Modal.Body>
          <div className="traning-modals-head">
            <h3 className="mb-4">Train The Pain!</h3>
          </div>
          <div className="traning-modals position-relative">
            <div className="tranning-modal-content">
              <p>
                If you suffer from Chronic Pain but still have a desire to be
                fit and active, then Bred Winner Fitness’ Train The Pain Program
                might be just what the doctor ordered. With formal education
                ranging from degrees and certifications in Sports Medicine,
                Pre-Physical Therapy, Occupational Therapy, Personal Training,
                Fitness Instruction, and Online Coaching; Bred Winner Trainers
                use their wealth of knowledge and resources to create and design
                One-of-a-kind “Therapeutic Fitness Routines”.{" "}
              </p>

              <p>
                Can you imagine a safe and effective personalized exercise
                program designed specifically for you? Not some cookie cutter,
                one size fits all program, that you have to modify because the
                exercises aggravate preexisting issues; but a 100% customized
                program with routines that have been safely and carefully
                designed, reviewed, and approved by licensed and certified
                professionals with your specific condition(s) in mind
              </p>

              <p>
                Bred Winner Fitness’ Therapeutic Fitness Routines prioritize and
                promote pain reduction and healing, while efficiently and
                effectively providing the results you’d expect from a successful
                fitness program. Results such as, burning fat, building muscle,
                increasing muscle stamina & endurance. Improving agility,
                balance, flexibility, and improving the cardiovascular and
                respiratory systems resulting in overall fitness which equates
                to a longer, healthier, and happier life.
              </p>
              <p>
                Bred Winner Trainers understand the incredible mental burden
                that Chronic Pain sufferers endure on a daily basis, often times
                making it difficult to even get out of bed. These mental
                challenges must first be overcome in order for any exercise
                program to be successful. Before we can begin to reshape the
                frame, we must first learn to ”Train The Pain!”{" "}
              </p>
              <p>
                Pain is a part of life and a great deterrent in many
                circumstances. Very few things have stalled more hopes and
                dreams or completely derailed plans, more than Chronic Pain, and
                since it is a part of life and therefore, unavoidable, we must
                learn to deal with it! All personal success comes from within.
                At Bred Winner Fitness we help our clients discover or uncover,
                feed and nourish, cultivate and grow, that Bred 2 Win Mentality.
                A refuse to lose, never give up or quit mentality that can only
                result in personal success!
              </p>
              <a href="/auth/register" className="read-more-read no-hover">
                Sign Up
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Train The Pain!(train the pain*/}
      <Modal show={groupTraining} backdrop="static" scrollable={true} size="lg">
        <button
          onClick={() => settgroupTraining(false)}
          className="traning-modals-head-buttons"
        >
          <FaTimes />
        </button>

        <Modal.Body>
          <div className="traning-modals-head">
            <h3 className="mb-4">Group Fitness </h3>
          </div>
          <div className="traning-modals position-relative">
            <div className="tranning-modal-content">
              <p>
                Bred Winner Fitness offers couples training sessions and group
                fitness classes. Couples training sessions can be customized to
                meet the fitness goals of both participants in one simple
                program. Bred Winner Trainers use the results from Individual
                assessments to safely design all custom programming. Group
                fitness classes and events will be announced when available.{" "}
              </p>
              <a href="/auth/register" className="read-more-read no-hover">
                Sign Up
              </a>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* online coachings  */}
      <Modal show={onlineCoching} backdrop="static" scrollable={true} size="lg">
        <button
          onClick={() => settOnlineCoching(false)}
          className="traning-modals-head-buttons"
        >
          <FaTimes />
        </button>

        <Modal.Body>
          <div className="traning-modals-head">
            <h3 className="mb-4">Online Coaching</h3>
          </div>
          <div className="traning-modals position-relative">
            <div className="tranning-modal-content">
              <p>
                Bred Winner Fitness offers Personal Training, and Online
                Coaching services including LIVE one on one and/or group
                sessions!!! You can sign up, register, join, or download right
                from our website{" "}
                <a className="web-links" href="www.bredwinnerfitness.com">
                  {" "}
                  www.bredwinnerfitness.com{" "}
                </a>
                Our clients have 24hr access to our site and all of its’
                resources. Including Health and Beauty services, Nutritional
                tips and full Uncensored access to Bred Winner Entertainment
                content via the Entertainment tab
              </p>
              <p>
                At bredwinnerfitness.com we have an extensive network of
                communication options to meet all or our Clients’ needs such as…
                Live chat, social media, email, phone and messaging options!
                LIVE or prerecorded sessions allow you the flexibility to
                incorporate Our routines in your daily life. LIVE One on One
                sessions, allows our Trainers to give instant feedback and make
                suggestions just as if they were right there with you
              </p>
              <a href="/auth/register" className="read-more-read no-hover">
                Sign Up
              </a>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClubClasses;
