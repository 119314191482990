import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertYearMonthDate } from "../../auth/DateConverions";
import Card from "react-bootstrap/Card";

const Trainers = () => {
  const [all, setAll] = useState([]);
  const [allUpcomming, setAllUpcomming] = useState([]);
  const [allContent, setAllContent] = useState([]);
  React.useEffect(() => {
    getAll();
    getAllUpcomming();
    getAllEntertainmentContent();
  }, []);

  const getAll = () => {
    apiGet(
      ENDPOINTS.AllEVENTSPHOTOS,
      (res) => {
        console.log(res.Result);
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllEntertainmentContent = () => {
    apiGet(
      ENDPOINTS.EntertainmentContentAll,
      (res) => {
        console.log(res.Result);
        setAllContent(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllUpcomming = () => {
    apiGet(
      ENDPOINTS.AllUpcommingEvents,
      (res) => {
        console.log(res.Result);
        setAllUpcomming(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <Container className="tranner-blogs">
        <Row className="tranner-rows">
          <Col md={12} sm={12} className="">
            <h4 className="h4-poppine-bold text-center">Current Events</h4>
            <hr className="green-center-hr" />
            {allContent.length > 0 &&
              allContent
                .filter((items) => items.key_name === "current_event_text")
                .map((item, index) => (
                  <p className="text-center" key={index}>
                    {item.key_value}
                  </p>
                ))}
            {/* <p className="text-center">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p> */}
          </Col>
        </Row>

        <Row className="">
          {all.length > 0 &&
            all.map((item, index) => (
              <Col key={index} lg={4} md={6} sm={12} className="colmun-tranner">
                <div className="tranding-img">
                  <img
                    src={ENDPOINTS.BaseUrl + item.eventImgPath}
                    className="h-transUp"
                    alt=""
                  />
                </div>
              </Col>
            ))}
        </Row>

        <Row className="tranner-rows mt-5">
          <Col md={12} sm={12} className="">
            <h4 className="h4-poppine-bold text-center">Upcoming Events</h4>
            <hr className="green-center-hr" />
            {allContent.length > 0 &&
              allContent
                .filter((items) => items.key_name === "upcoming_event_text")
                .map((item, index) => (
                  <p className="text-center" key={index}>
                    {item.key_value}
                  </p>
                ))}
            {/* <p className="text-center">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p> */}
          </Col>
        </Row>

        <Row className="">
          {allUpcomming.length > 0 &&
            allUpcomming.map((item, index) => (
              <Col key={index} lg={4} md={6} sm={12} className="colmun-tranner">
                <Card className="upcommig-event-div">
                  <Card.Img
                    variant="top"
                    src={ENDPOINTS.BaseUrl + item.image_path}
                    className="upcommig-event"
                  />
                  <Card.Body>
                    <Card.Title className="position-relative">
                      {item.title}{" "}
                    </Card.Title>
                    <h5 className="upcommig-event-date">
                      {ConvertYearMonthDate(item.date)}{" "}
                    </h5>
                    <Card.Text className="font-14 contents-upcommings">
                      {item.content}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default Trainers;
