// // // live
// export const BASE_URL = "http://test.gologonow.tech";
// export const BASE_URLS = "http://test.gologonow.tech";

// // client
export const BASE_URL = "https://bredwinnerfitness.com/fitness-node";
export const BASE_URLS = "https://bredwinnerfitness.com/fitness-node";

// // // // // test
// export const BASE_URL = "http://localhost:8082";
// export const BASE_URLS = "http://localhost:8082";

// // Local server
// export const BASE_URL = "http://192.168.5.251/fitness-node";
// export const BASE_URLS = "http://192.168.5.251/fitness-node";

export const ENDPOINTS = {
  UserLogin: `${BASE_URL}/admin/user/login`,
  BaseUrl: `${BASE_URLS}`,

  TrainThePainAll: `${BASE_URL}/front/dynamic/get-train-pain-image`,
  AddTrainThePain: `${BASE_URL}/admin/dynamic/add-train-pain-image`,
  DeleteTrainThePain: `${BASE_URL}/admin/dynamic/delete-train-pain-image`,

  HomeSliderAll: `${BASE_URL}/front/dynamic/get-home-slider-image`,
  AddHomeSlider: `${BASE_URL}/admin/dynamic/add-home-slider-image`,
  DeleteHomeSlider: `${BASE_URL}/admin/dynamic/delete-home-slider-image`,

  EntertainmentAll: `${BASE_URL}/front/dynamic/get-entertainment-slider-image`,
  AddEntertainment: `${BASE_URL}/admin/dynamic/add-entertainment-slider-image`,
  DeleteEntertainment: `${BASE_URL}/admin/dynamic/delete-entertainment-slider-image`,

  EntertainmentContentAll: `${BASE_URL}/front/dynamic/entertainment-content-text`,
  EntertainmentContentAdd: `${BASE_URL}/admin/dynamic/entertainment-content-text`,

  AllEmailHistory: `${BASE_URL}/admin/email-history/all?skip=`,

  AllEmailGroup: `${BASE_URL}/admin/group-detail/`,
  UpdateStatusEmail: `${BASE_URL}/admin/email-history/update`,
  UpdateStatusEmailGroup: `${BASE_URL}/admin/email-history-group/update`,
  AllEmails: `${BASE_URL}/admin//email/all`,

  getAllCategory: `${BASE_URL}/front/profile-category/get`,
  getAllCategoryUser: `${BASE_URL}/user/profile-category/get`,

  getAllUserPackages: `${BASE_URL}/user/package/current-user`,
  getAllUserPackagesSessions: `${BASE_URL}/user/package/without-buy`,

  getPackagesByCategory: `${BASE_URL}/admin/packages-category/`,
  getPackagesByCategoryForUser: `${BASE_URL}/front/packages-category/`,

  AllVideosOfCategoryLibaray: `${BASE_URL}/admin/category-libraries/all-videos?skip=`,
  AllImagesOfCategoryLibaray: `${BASE_URL}/admin/category-libraries/all-images?skip=`,
  AllOtherOfCategoryLibaray: `${BASE_URL}/admin/category-libraries/all-other?skip=`,

  UserAllVideosOfCategoryLibaray: `${BASE_URL}/user/category-libraries/all-videos?skip=`,
  UserAllImagesOfCategoryLibaray: `${BASE_URL}/user/category-libraries/all-images?skip=`,
  UserAllOtherOfCategoryLibaray: `${BASE_URL}/user/category-libraries/all-other?skip=`,

  PackageUsers: `${BASE_URL}/admin/packages-users/all?`,

  AllPackage: `${BASE_URL}/admin/package/all`,
  updatePackage: `${BASE_URL}/admin/package/update`,

  SendsInvites: `${BASE_URL}/admin/send-invites`,

  // Count Admin
  AllUserCount: `${BASE_URL}/admin/user/get`,
  AllPackageUserCount: `${BASE_URL}/admin/package-user/get`,
  AllAppointmentCount: `${BASE_URL}/admin/appointment-count/get`,
  AllTrackCount: `${BASE_URL}/admin/track-count/get`,

  AllUSers: `${BASE_URL}/admin/all-user/get`,
  AllUSersCat: `${BASE_URL}/admin/all-user-cat/get?id=`,
  UpdateUser: `${BASE_URL}/admin/user/update`,
  DeleteUser: `${BASE_URL}/admin/users/delete/`,

  // reviews
  AddReview: `${BASE_URL}/admin/review/add`,
  AllReviews: `${BASE_URL}/admin/review/get`,
  SingleReview: `${BASE_URL}/admin/review/get/`,
  UpdateReview: `${BASE_URL}/admin/review/update`,
  DeleteReview: `${BASE_URL}/admin/review/delete/`,

  // reviews
  AddClass: `${BASE_URL}/admin/ourclasses/add`,
  AllClasses: `${BASE_URL}/admin/allclasses/get`,
  SingleClasses: `${BASE_URL}/admin/ourclasses/get/`,
  UpdateClasses: `${BASE_URL}/admin/ourclasses/update`,
  DeleteClass: `${BASE_URL}/admin/ourclasses/delete/`,

  // Tracks
  AddTracks: `${BASE_URL}/admin/track/add`,
  AllTracks: `${BASE_URL}/admin/track/get`,
  SingleTracks: `${BASE_URL}/admin/track/get/`,
  UpdateTracks: `${BASE_URL}/admin/track/update`,
  DeleteTracks: `${BASE_URL}/admin/track/delete/`,

  UserTracks: `${BASE_URL}/user/track/get`,

  UserTracksCount: `${BASE_URL}/user/track-count/get`,

  //home Tracks
  AddHomeTracks: `${BASE_URL}/admin/home-track/add`,
  AllHomeTracks: `${BASE_URL}/front/home-track/get`,
  SingleHomeTracks: `${BASE_URL}/admin/home-track/get/`,
  UpdateHomeTracks: `${BASE_URL}/admin/home-track/update`,
  DeleteHomeTracks: `${BASE_URL}/admin/home-track/delete/`,

  // EventVides
  AddStream: `${BASE_URL}/admin/stream/add`,
  AllStream: `${BASE_URL}/admin/stream/get`,
  SingleStream: `${BASE_URL}/admin/stream/get/`,
  UpdateStream: `${BASE_URL}/admin/stream/update`,

  // Event Vides
  AddEventVideos: `${BASE_URL}/admin/event-video/add`,
  AllEventVideos: `${BASE_URL}/admin/event-video/get`,
  SingleEventVideos: `${BASE_URL}/admin/event-video/get/`,
  UpdateEventVideos: `${BASE_URL}/admin/event-video/update`,
  DeleteEventVideos: `${BASE_URL}/admin/event-video/delete/`,

  // EventPhoto
  AddEventPhoto: `${BASE_URL}/admin/event-photo/add`,
  AllEventPhoto: `${BASE_URL}/admin/event-photo/get`,
  SingleEventPhoto: `${BASE_URL}/admin/event-photo/get/`,
  UpdateEventPhoto: `${BASE_URL}/admin/event-photo/update`,
  DeleteEventPhoto: `${BASE_URL}/admin/event-photo/delete/`,

  // HealthAndBeauty
  AddHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/add`,
  AllHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/get`,
  SingleHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/get/`,
  UpdateHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/update`,
  DeleteHealthAndBeauty: `${BASE_URL}/admin/health-and-beauty/delete/`,

  //appointment
  AllAppointment: `${BASE_URL}/admin/appointment/get`,
  SingleAppointment: `${BASE_URL}/admin/appointment/get/`,
  UpdateAppointment: `${BASE_URL}/admin/appointment/update`,
  DeleteAppointment: `${BASE_URL}/admin/appointment/delete/`,
  UserAppointments: `${BASE_URL}/user/appointment/get`,
  UserTranings: `${BASE_URL}/user/tranning/user`,

  UserAppointmentsCount: `${BASE_URL}/user/appointment-count/get`,

  //order
  AllUserOrders: `${BASE_URL}/user/order/get`,

  // Services
  AllServices: `${BASE_URL}/front/Services/get`,
  SingleServices: `${BASE_URL}/admin/services/get/`,
  UpdateServices: `${BASE_URL}/admin/services/update`,
  DeleteServices: `${BASE_URL}/admin/services/delete/`,
  CreateServices: `${BASE_URL}/admin/services/add`,

  //category libraries
  UploadCategoryMedia: `${BASE_URL}/admin/category-libraries/add`,

  //Sessions
  AddSessions: `${BASE_URL}/admin/session/add`,
  AllSessions: `${BASE_URL}/admin/session/get`,
  AllSessionsByType: `${BASE_URL}/user/session-with-type/get/`,
  AllUserSessions: `${BASE_URL}/user/session/get`,
  UserBuyCategoryLibrarie: `${BASE_URL}/user/category-libraries-user`,
  SingleSessions: `${BASE_URL}/user/session/get/`,
  AllSessionBySessionId: `${BASE_URL}/admin/session-slot-sessionid/get/`,
  AllSessionBySessionIdForUser: `${BASE_URL}/user/session-slot-sessionid/get/`,
  UpdateSlotSession: `${BASE_URL}/admin/session-slot/update`,
  DeleteSessionSlotsvideo: `${BASE_URL}/admin/session-slot-video/delete`,
  UpdateSessions: `${BASE_URL}/admin/session/update`,
  DeleteSessions: `${BASE_URL}/admin/session/delete/`,

  //Sessions
  AddSessionsSlots: `${BASE_URL}/admin/session-slot/add`,

  //front end
  AllHealthAndBeautyFront: `${BASE_URL}/front/health-and-beauty/get`,
  AllTracksFront: `${BASE_URL}/front/track-all/get`,
  AllEVENTSPHOTOS: `${BASE_URL}/front/event-photo/get`,
  AllEVENTSVIDEO: `${BASE_URL}/front/event-video/get`,
  ADDAPPOINTMENT: `${BASE_URL}/front/appointment/add`,
  AddPayments: `${BASE_URL}/front/payment/add`,
  AddPaymentsPaypal: `${BASE_URL}/front/payment/paypal-add`,
  AddAgentsPayment: `${BASE_URL}/user/payment/thank-you`,
  AddpayPalPaymentSucssess: `${BASE_URL}/front/payment/paypal-success`,
  PayPalPaymentCancel: `${BASE_URL}/front/payment/paypal-cancel`,
  CreateUser: `${BASE_URL}/front/user/register`,

  trackOrderTrack: `${BASE_URL}/user/order-check/`,

  LibraruOrderCheck: `${BASE_URL}/user/order-libray-exsis`,
  OnlineCoachingOrderCheck: `${BASE_URL}/user/order-online-coaching-exsis`,

  // Add Upcoming Events
  AddUpcommingEvents: `${BASE_URL}/admin/upcomming-events/add`,
  AllUpcommingEvents: `${BASE_URL}/front/upcomming-events/all`,
  DeleteUpcommingEvents: `${BASE_URL}/admin/upcomming-events-delete/`,
  UpdateUpcommingEvents: `${BASE_URL}/admin/upcomming-events/update`,

  UpdateProfileCategory: `${BASE_URL}/admin/profile-category/update`,
  CreateProfileCategory: `${BASE_URL}/admin/profile-category/add`,

  SingleTracks: `${BASE_URL}/admin/track/get/`,
  UpdateTracks: `${BASE_URL}/admin/track/update`,
  DeleteTracks: `${BASE_URL}/admin/track/delete/`,


  ProfileStatusChange: `${BASE_URL}/admin/profile-category/status`,
  ContactUsForm: `${BASE_URL}/front/contact-us/email`,
};
