import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import T1 from "../../assets/images/t-1.webp";
import Facebook from "../../assets/images/icon/facebook.webp";
import Instagram from "../../assets/images/icon/instagram.webp";
import Twitter from "../../assets/images/icon/twitter.webp";
import T2 from "../../assets/images/t-2.webp";
import T3 from "../../assets/images/t-3.webp";
import RiseRes from "../../assets/images/rise-res.webp";
import Bounce from "react-reveal/Bounce";
import Online from "../../assets/images/on-line.webp";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import ArrowLeft from "../Global/ArrowLeft";
import ArrowRight from "../Global/ArrowRight";
import { GrFormPrevious } from "react-icons/gr";

const MeetTeam = () => {
  return (
    <div>
      <Container fluid className="mt-5 mb-5">
        <Row>
          <Col md={12} lg={12} className="meet-our-teams position-relative ">
            <div className="meet-our-teams-text ">

              <div className='position-relative heading-arrow-top' onClick={() => {
                window.location.href = '/services';
              }}>
                <ArrowRight colors="#ff0e0e" />
                <div className="text-ghatya-blue">
                  <Zoom duration={1800} right>
                    <h2 className="">G</h2>
                  </Zoom>
                  <Zoom duration={2000} right>
                    <h2 className="">r</h2>
                  </Zoom>
                  <Zoom duration={2200} right>
                    <h2 className="">o</h2>
                  </Zoom>
                  <Zoom duration={2400} right>
                    <h2 className="">u</h2>
                  </Zoom>
                  <Zoom duration={2600} right>
                    <h2 className="">p</h2>
                  </Zoom>
                  <Zoom duration={2600} right>
                    <h2 className=""></h2>
                  </Zoom>
                  <Zoom duration={2800} right>
                    <h2 className="">F</h2>
                  </Zoom>
                  <Zoom duration={3000} right>
                    <h2 className="">i</h2>
                  </Zoom>
                  <Zoom duration={3200} right>
                    <h2 className="">t</h2>
                  </Zoom>
                  <Zoom duration={3400} right>
                    <h2 className="">n</h2>
                  </Zoom>
                  <Zoom duration={3600} right>
                    <h2 className="">e</h2>
                  </Zoom>
                  <Zoom duration={3800} right>
                    <h2 className="">s</h2>
                  </Zoom>
                  <Zoom duration={4000} right>
                    <h2 className="">s</h2>
                  </Zoom>

                </div>
                <ArrowLeft colors="#ff0e0e" />
              </div>
              {/* <h2 className="popin-bold font-dark-green ">Group Fitness</h2> */}
              <hr className="hr-centers" />
              <p className="">
                Bred Winner Fitness offers couples training sessions and group
                fitness classes. Couples training sessions can be customized to
                meet the fitness goals of both participants in one simple
                program. Bred Winner Trainers use the results from Individual
                assessments to safely design all custom programming. Group
                fitness classes and events will be announced when available.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5 mb-5 p-0">
        <div className="we-rise-block">
          <Row className="no-gutters">
            <Col
              md={12}
              lg={5}
              className="rise-div-box-column position-relative "
            >
              <Bounce top>
                {/* <div className="rise-div-box">
                                    <h2 className="popin-bold font-dark-green ">WE RAISE YOUR CONFIDENCE</h2>
                                    <p className="text-white">If you run a fitness gym, this is one slogan that can bring customers in. No one likes having to drive miles out of their way to go to the gym. It is always easier to have a gym that is actually close to home. Your body hears everything that your mind says.</p>
                                </div> */}
              </Bounce>
            </Col>
            <Col md={12} lg={12} className="rise-div-box-img ">
              {/* <img src={Rise} className="" alt="ss" /> */}
              <img src={RiseRes} alt="ss" />
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="mt-5 mb-5 ">
        <Row className="mb-4 online-clases">
          <Col sm={12} md={12} className="text-center position-relative mb-5">
            <div className='position-relative heading-arrow-top' onClick={() => { window.location.href = '/services' }}>
              <ArrowRight colors="#ff0e0e" />

              <div
                href="/services"
                className="text-ghatya-blue blinking color-greens"
              >
                <Zoom duration={1800} right>
                  <h2 className="">O</h2>
                </Zoom>
                <Zoom duration={2000} right>
                  <h2 className="">n</h2>
                </Zoom>
                <Zoom duration={2200} right>
                  <h2 className="">l</h2>
                </Zoom>
                <Zoom duration={2400} right>
                  <h2 className="">i</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className="">n</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className="">e</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className=""></h2>
                </Zoom>
                <Zoom duration={2800} right>
                  <h2 className="">C</h2>
                </Zoom>
                <Zoom duration={3000} right>
                  <h2 className="">o</h2>
                </Zoom>
                <Zoom duration={3200} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={3400} right>
                  <h2 className="">c</h2>
                </Zoom>
                <Zoom duration={3600} right>
                  <h2 className="">h</h2>
                </Zoom>
                <Zoom duration={3800} right>
                  <h2 className="">i</h2>
                </Zoom>

                <Zoom duration={3800} right>
                  <h2 className="">n</h2>
                </Zoom>

                <Zoom duration={4000} right>
                  <h2 className="">g</h2>
                </Zoom>
              </div>
              <ArrowLeft colors="#ff0e0e" />

            </div>

            <hr className="hr-centers" />
            <p className="text-center mb-5">
              Bred Winner Fitness offers Personal Training, and Online Coaching
              services including LIVE one on one and/or group sessions!!! You
              can sign up, register, join, or download right from our website{" "}
              <a className="web-links" href="www.bredwinnerfitness.com">
                {" "}
                www.bredwinnerfitness.com{" "}
              </a>
              Our clients have 24hr access to our site and all of its’
              resources. Including Health and Beauty services, Nutritional tips
              and full Uncensored access to Bred Winner Entertainment content
              via the Entertainment tab
            </p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={12} className=" position-relative">
            <Flip top>
              <img src={Online} className="online-clases-img" alt="das" />
            </Flip>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 mb-5">
        <Row>
          <Col md={12} lg={12} className="meet-our-teams position-relative ">
            <div className="meet-our-teams-text">
            <div className='position-relative heading-arrow-top' onClick={() => { window.location.href = '/health-and-beauty' }}>
              <ArrowRight colors="#ff0e0e" />

              <div
                className="text-ghatya-blue blinking color-greens"
              >
               
               <Zoom duration={1800} right>
                  <h2 className="">B</h2>
                </Zoom>
                <Zoom duration={2000} right>
                  <h2 className="">e</h2>
                </Zoom>
                <Zoom duration={2200} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={2400} right>
                  <h2 className="">u</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className="">t</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className="">y</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className=""></h2>
                </Zoom>
                <Zoom duration={2800} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={3000} right>
                  <h2 className="">n</h2>
                </Zoom>
                <Zoom duration={3200} right>
                  <h2 className="">d</h2>
                </Zoom>
                <Zoom duration={3400} right>
                  <h2 className=""></h2>
                </Zoom>
                <Zoom duration={3400} right>
                  <h2 className="">H</h2>
                </Zoom>
                <Zoom duration={3600} right>
                  <h2 className="">e</h2>
                </Zoom>
                <Zoom duration={3800} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={4000} right>
                  <h2 className="">l</h2>
                </Zoom>

                <Zoom duration={4200} right>
                  <h2 className="">t</h2>
                </Zoom>

                <Zoom duration={4400} right>
                  <h2 className="">h</h2>
                </Zoom>
              </div>
              <ArrowLeft colors="#ff0e0e" />

            </div>
         
              <hr className="hr-centers" />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 pb-5">
          <Col
            sm={12}
            md={4}
            lg={4}
            className="meet-our-teams-coulmn position-relative "
          >
            <Fade top>
              <div className="meet-our-teams-img">
                <Nav.Link href="/health-and-beauty" className="navs-item">
                  <img
                    src={T1}
                    alt="sss"
                    className="meet-our-teams-imgss meet-our-teams-imgss-custom "
                  />
                  <div className="meet-our-teams-img-text">
                    {/* <h3 className="popin-light ">Higgins Emma</h3> */}
                    {/* <h5 className="text-gray">Body Bulding</h5> */}
                    <div className="socilas-link-for-team">
                      <Nav.Link href="https://www.facebook.com/BredWinnerFitness/">
                        <img src={Facebook} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="#">
                        <img src={Instagram} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="http://linkedin.com/in/coach-taa-336792171">
                        <img src={Twitter} alt="dasd" />
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
              </div>
            </Fade>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="meet-our-teams-coulmn position-relative "
          >
            <Fade bottom>
              <div className="meet-our-teams-img">
                <Nav.Link href="/health-and-beauty" className="navs-item">
                  <img
                    src={T2}
                    className="meet-our-teams-imgss  meet-our-teams-imgss-custom"
                    alt="sss"
                  />
                  <div className="meet-our-teams-img-text">
                    {/* <h3 className="popin-light ">Kojee Robinson</h3> */}
                    {/* <h5 className="text-gray">Fitness Couach</h5> */}
                    <div className="socilas-link-for-team">
                      <Nav.Link href="https://m.facebook.com/BredWinnerFitness/">
                        <img src={Facebook} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="#">
                        <img src={Instagram} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="http://linkedin.com/in/coach-taa-336792171">
                        <img src={Twitter} alt="dasd" />
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
              </div>
            </Fade>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="meet-our-teams-coulmn  position-relative "
          >
            <Fade top>
              <div className="meet-our-teams-img">
                <Nav.Link href="/health-and-beauty" className="navs-item">
                  <img
                    src={T3}
                    alt="sss"
                    className="meet-our-teams-imgss  meet-our-teams-imgss-custom"
                  />
                  <div className="meet-our-teams-img-text">
                    {/* <h3 className="popin-light ">Skye Sorenson</h3> */}
                    {/* <h5 className="text-gray">Personal Trainer</h5> */}
                    <div className="socilas-link-for-team">
                      <Nav.Link href="https://m.facebook.com/BredWinnerFitness/">
                        <img src={Facebook} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="#">
                        <img src={Instagram} alt="dasd" />
                      </Nav.Link>
                      <Nav.Link href="http://linkedin.com/in/coach-taa-336792171">
                        <img src={Twitter} alt="dasd" />
                      </Nav.Link>
                    </div>
                  </div>
                </Nav.Link>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MeetTeam;
