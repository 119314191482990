  import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Col, Row } from "reactstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Header from "components/Headers/Header.js";
import Loader from "components/Global/Loader";
import CustomTooltip from "components/Global/CustomTooltip";
import NotificationPopup from "components/Global/NotificationPopup";
import { apiGetAuth, apiPost, apiPut,apiPostWithoutAuth } from "../../auth/APIRequests";
import Modal from "react-bootstrap/Modal";
import PlaceholderImg from "../../assets/images/placeholder-img.webp";

import { ENDPOINTS } from "../../auth/EndPoints";
import { CheckImageExtension } from "../../auth/DateConverions";
import imageNotFound from "../../assets/images/image-not-found.webp";
import UpdateProfileCategory from "../../components/Forms/UpdateProfileCategory";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'


const Categories = () => {
  document.title = "services";
  const [isLoading, setIsLoading] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [all, setAll] = useState([]);
  const [modalPcakageUpdate, setModalPcakageUpdate] = useState(false);
  const [modalPcakageCreate, setModalPcakageCreate] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [showSnack, setSnack] = useState(false);

  const [selectedItem, setSelectedItem] = useState({
    id: "",
    name: "",
    price: "",
    description: "",
    media: "",
  });

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (item) => {
    setUpdateModalShow(true);
    setSelectedItem({
      id: item.id,
      name: item.name,
      price: item.price,
      description: item.description,
      media: item.media,
    });
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.getAllCategory,
      (res) => {
        setAll(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const checkFilesAcceptImg = (e) => {
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept jpg,png,svg  files");
    }
  };

  const changeStatus = (ItemId) => {
  const data ={
    id:ItemId
  }
  apiPost(
      ENDPOINTS.ProfileStatusChange,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("change successfully!");
        setIsLoading(false);
        getAll();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setIsLoading(false);
        getAll();
      }
    );
  }
  // create
  const handleSubmitCreate = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.CreateProfileCategory,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("create Successfully!");
        setIsLoading(false);
        setModalPcakageCreate(false);
        getAll();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setModalPcakageCreate(false);
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 category-btns">
                <h3 className="mb-0">Services </h3>
                <Button
                  variant="primary"
                  onClick={() => setModalPcakageCreate(true)}
                >
                  {" "}
                  Add{" "}
                </Button>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Profile</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Description</th>
                      <th>Service status</th>
                      <th>Packages</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              className="avatar"
                              src={
                                item?.media
                                  ? ENDPOINTS.BaseUrl + item?.media
                                  : PlaceholderImg
                              }
                            />
                            {/* {item?.media} */}
                          </td>
                          <td>{item?.name}</td>
                          <td>{item?.price}</td>
                          <td>
                            <CustomTooltip descriptionPro={item?.description} />
                          </td>
                          <td><BootstrapSwitchButton    onChange={()=>changeStatus(item.id)} checked={item?.status} onstyle="primary"  size="sm" /></td>
                          <td>
                            <a
                              href={`/admin/package/${item.id}`}
                              className="btn btn-info font-14"
                              // href={() => getDetail(item)}
                            >
                              Packages
                            </a>
                          </td>
                          <td>
                            <button
                              className="btn btn-danger font-14"
                              onClick={() => getDetail(item)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>

      <UpdateProfileCategory
        updateModalShow={updateModalShow}
        selectedItem={selectedItem}
        closeModal={() => setUpdateModalShow(false)}
        hideLoader={() => setIsLoading(false)}
        loadAll={() => getAll()}
      />
      {/* create */}
      <Modal show={modalPcakageCreate} backdrop="static">
        <Modal.Body>
          <Form onSubmit={handleSubmitCreate}>
            <Form.Group className="mb-3">
              <Form.Label> Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                required
                type="number"
                name="price"
                placeholder="Price"
                min="1"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Desription</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                name="description"
                placeholder="Description here!"
                min="1"
                // value={Price}
                // onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Chose Image</Form.Label>
              <Form.Control
                required
                type="file"
                name="image"
                id="img-input"
                onChange={(e) => checkFilesAcceptImg(e)}
                placeholder="Client Review"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Single Session price</Form.Label>
              <Form.Control
                required
                type="number"
                name="single_session_price"
                placeholder="Single Session price"
                min="1"
              />
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>1 Month price</Form.Label>
              <Form.Control
                required
                type="number"
                name="one_month_price"
                placeholder="1 month price"
                min="1"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>3 Month price</Form.Label>
              <Form.Control
                required
                type="number"
                name="three_month_price"
                placeholder="3 month price"
                min="1"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>6 Month Price</Form.Label>
              <Form.Control
                required
                type="number"
                name="six_month_price"
                placeholder="6 month price"
                min="1"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>1 Year Price</Form.Label>
              <Form.Control
                required
                type="number"
                name="twelve_month_price"
                placeholder="1 year Price"
                min="1"
              />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                onClick={() => setModalPcakageCreate(false)}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default Categories;
