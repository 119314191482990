import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";

import { ENDPOINTS } from "../../auth/EndPoints";
import { apiPut } from "../../auth/APIRequests";
import { CheckImageExtension } from "../../auth/DateConverions";
import imageNotFound from "../../assets/images/image-not-found.webp";

const UpdateProfileCategory = ({
  selectedItem,
  updateModalShow,
  closeModal,
  hideLoader,
  loadAll,
}) => {
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [showSnack, setSnack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkFilesAcceptImg = (e) => {
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept jpg,png,svg  files");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.UpdateProfileCategory,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("update Successfully!");
        closeModal();
        hideLoader();
        setIsLoading(false);
        loadAll();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        closeModal();
        hideLoader();
        setIsLoading(false);
        loadAll();
      }
    );
  };
  return (
    <>
      {isLoading && <Loader />}

      <Modal show={updateModalShow} backdrop="static">
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label> Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
                defaultValue={selectedItem.name}
                onChange={(e) => {
                  selectedItem = {
                    ...selectedItem,
                    name: e.target.value,
                  };
                }}
              />
              <Form.Control
                required
                type="hidden"
                name="id"
                value={selectedItem.id}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                required
                type="number"
                name="price"
                placeholder="Price"
                min="1"
                defaultValue={selectedItem.price}
                onChange={(e) => {
                  selectedItem = {
                    ...selectedItem,
                    price: e.target.value,
                  };
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Desription</Form.Label>
              <Form.Control
                className="description-categories"
                required
                as="textarea"
                rows={3}
                name="description"
                placeholder="Description"
                min="1"
                defaultValue={selectedItem.description}
                onChange={(e) => {
                  selectedItem = {
                    ...selectedItem,
                    description: e.target.value,
                  };
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Chose Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                id="img-input"
                onChange={(e) => checkFilesAcceptImg(e)}
                placeholder="Client Review"
              />
              <img
                className="seleced-item-image"
                src={
                  selectedItem?.media
                    ? ENDPOINTS.BaseUrl + selectedItem?.media
                    : imageNotFound
                }
              />
            </Form.Group>

            <div className="text-right">
              <Button variant="info" onClick={() => closeModal()}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default UpdateProfileCategory;
