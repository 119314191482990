import React, { useState, useEffect, useContext } from "react";
import { Card, Container, CardBody, Col, Row } from "reactstrap";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertDateStandard } from "../../auth/DateConverions";
import { BioData } from "../../views/Index";
import Loader from "./Loader";

import Pagination from "react-js-pagination";

const VideosMediaCat = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [all, setAll] = useState([]);
  const [countRecord, setCountRecord] = useState(1);
  const [curentPage, setCurentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAll(pageNumber);
  }, []);
  const getAll = async (page) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.AllVideosOfCategoryLibaray + page,
      (res) => {
        console.log(res.result);
        setCountRecord(res.result.count);
        setAll(res.result.rows);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);

        console.log(error);
      }
    );
  };

  const playePouseAudio = (e, id) => {
    var audios = document.querySelectorAll(".audio");
    if (audios.length > 0) {
      for (var i = 0; i < audios.length; i++) {
        if (e.target.id !== audios[i].id) {
          audios[i].pause();
        }
      }
    }
  };

  const changePagination = (page) => {
    setCurentPage(page);
    getAll(page);
  };

  return (
    <>
      <Container className="mt-5" fluid>
        <Row className="mt-4">
          {all.length > 0 &&
            all.map((item, index) => (
              <Col lg={3} key={index} md={4} sm={6} className="mb-4" xs={12}>
                <Card>
                  <CardBody className="package-videos-card">
                    <video
                      controls
                      className="videos-sessions audio"
                      id={item?.id}
                      onPlay={(e) => playePouseAudio(e, item?.id)}
                    >
                      <source
                        src={ENDPOINTS.BaseUrl + item?.path}
                        type="video/mp4"
                      />
                    </video>
                    <h1 className="package-videos-card-h1 text-primary">
                      {item?.media_title}
                    </h1>
                    <h5>{item?.profile_category?.name}</h5>

                    <h6 className="">{ConvertDateStandard(item.createdAt)}</h6>
                    <div>
                      <h6 className="package-videos-card-h2">
                        {item.file_type}
                      </h6>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {all.length < 1 && <div className="no-record"> Record Found</div>}
        </Row>
        <div>
          <div className="pagination-boxes">
            <Pagination
              activePage={curentPage}
              itemsCountPerPage={12}
              totalItemsCount={countRecord}
              itemClass="custom-paginations"
              pageRangeDisplayed={5}
              onChange={changePagination}
            />
          </div>
        </div>
      </Container>
      {isLoading && <Loader />}
    </>
  );
};

export default VideosMediaCat;
