import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import {
  FaFacebookF,
  FaGooglePlusG,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt,
  FaRegClock,
  FaRegEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import AOS from "aos";

const Footer = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="footer-back position-relative">
        <div className="footer-content" data-aos="fade-up">
          <Container>
            <Row>
              <Col md={6} sm={12} className="mb-3 position-relative">
                <div className="footers-texts">
                  <p className="text-white text-left text-center-767">
                    At Bred Winner Fitness our motto is Bred 2 Win!!! We believe
                    that success comes from within! As a result, our highly
                    skilled Certified Trainers and Licensed Therapist also
                    specialize in connecting with our clients to help them
                    discover and unlock The Winner that’s inbred in us all!
                  </p>
                  <div className="socials-icons">
                    <Nav.Link href="#" className="">
                      <FaFacebookF className="socials-icons-icons" />
                    </Nav.Link>
                    <Nav.Link href="#" className="">
                      <FaGooglePlusG className="socials-icons-icons" />
                    </Nav.Link>
                    <Nav.Link href="#" className="">
                      <FaTwitter className="socials-icons-icons" />
                    </Nav.Link>
                    <Nav.Link href="#" className="">
                      <FaInstagram className="socials-icons-icons" />
                    </Nav.Link>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12} className="mb-3">
                <div className="get-in-touch-footer">
                  <Row>
                    <Col md={12} sm={12} className="mb-3">
                      <h2 className="text-white mb-2">Get In Touch</h2>
                      <hr className="white-hrs hrs-lefts" />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <h5 className="text-white mb-2">Address</h5>
                      <p className="mn-3 text-white">
                        <FaMapMarkerAlt className="me-2" />
                        P.O. Box 18228 Cincinnati, Ohio 45218
                      </p>
                      <p className="  text-white">
                        <FaRegClock className="me-2" /> Mon - Fri 08:00 AM -
                        09:00 PM
                      </p>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <h5 className=" text-white  mb-2 hrs-lefts">
                        Contact Info
                      </h5>
                      <p className="mn-3  emsila-1 text-white">
                        <a href="mailto:bredwinnerfitness@gmail.com">
                        
                          <span>
                            <FaRegEnvelope className="me-2" />
                          </span>
                          bredwinnerfitness@gmail.com
                        </a>
                      </p>
                      <p className=" text-white">
                      
                        <a href="tel:513-253-1350" className="text-white">
                          <FaPhoneAlt className="me-2" /> 513-253-1350
                        </a>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="copy-right-footer">
                  <hr className="footer-last-hr" />
                  <p className="text-white text-center pt-2 pb-2">
                    Copyright © 2021 GYM. All rights reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Footer;
