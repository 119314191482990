import React,{useState} from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { apiGetAuth } from '../../auth/APIRequests';
import { ENDPOINTS } from '../../auth/EndPoints';
import { saveAs } from "file-saver";

const UserTracks = () => {
  document.title = "Tracks";

  const [all, setAll] = useState([]);
  React.useEffect(() => {
    getAll();
  },[]);
  function getAll() {
    apiGetAuth(
      ENDPOINTS.UserTracks,
      (res) => {
         setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const songDonwload = async (src) => {
    saveAs(src)
  }
  const playePouseAudio = (e,id) =>{
    var audios = document.querySelectorAll('.audio');
    if (audios.length > 0){
      for (var i=0; i < audios.length; i++){
        if (e.target.id !== audios[i].id) {
          audios[i].pause();
        }
      }
    }    
  }
  return (
    <>
      <UserHeader />
      {/* Table */}
      <Container  className="mt--5" fluid>
      {all.length >0 &&(
      <Row>
      {all.map((item,index) =>
       <Col xl={4} md={6} sm={6} xs={12} key={index}>
       <Card >
         <CardBody>
         <CardTitle className="card-titles">Track Name: {item.track.trackName} <span className="amount-in-card">{' $'}{item.track.trackPrice}</span> </CardTitle>
           <CardText>
           <audio className=" audio width-250" id={item.id} onPlay={(e)=> playePouseAudio(e,item.id)} controls="controls" controlsList="nodownload">
                <source src={ENDPOINTS.BaseUrl+''+item.track.trackSource} type="audio/mp3"></source>
            </audio>
            <div className="text-center mt-4">
            <button className="btn btn-primary" onClick={()=>songDonwload(ENDPOINTS.BaseUrl+''+item.track.trackSource)}>Download song</button>
            </div>
          
           </CardText>
         </CardBody>
       </Card>
       </Col>
      )}
          </Row>
                )}
                {all.length < 1 &&(
                  <div  className="no-record">  Record Found</div>
              )}




      </Container>
    </>
  );
};

export default UserTracks;
