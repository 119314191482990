import moment from 'moment';


export const ConvertDateTime = (date) => {
  return moment.utc(date).format('DD-MMM-YYYY hh:mm A');
}
export const ConvertDay = (date) => {
  return moment.utc(date).format('D');
}
export const ConvertMonth = (date) => {
  return moment.utc(date).format('MMM');
}
export const ConvertTimeStampInToDate = (date) => {
	return moment.utc(date).format('DD-MM-YYYY');
};

export const ConvertDateinToTime = (date) => {
	return moment(date).format('hh:mm A');
};
export const ConvertDefaultHtml = (date) => {
	return moment(date).format('YYYY-MM-DDTHH:mm');
};
export const ConvertDefaultHtmlCurremt = () => {
  var today = new Date()
	return moment(today).format('YYYY-MM-DDTHH:mm');
};
export const MaxDateValidation = () => {
  var today = new Date()
	return moment(today).format('YYYY-MM-DD');
};
export const ConvertDateStandard = (date) => {
  return moment(date).format('MMMM Do YYYY');
};
export const ConvertTimeStandard = (date) => {
  return moment(date).format('h:mm a');
};
export const ConvertYearMonthDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const ReplaceUnderscores = (date) => {
  var replace = date.replaceAll('_',' ');

  return replace;
};
export const DateWithMOnthName = (date) => {
 return moment(date).format('DD MMMM YYYY');
};
export const dateTimeNow = () => {
  return moment().format();
 };
export const ChatTime = (date) => {
  return moment(date).format('LT');
};
export const ConvertInTime = (date) => {
 const times = date.split(':');
     return `${times[0]}:${times[1]}`;
};
export const CheckImageExtension = (filename) => {
 const imgType = ['jpg','png','svg'] ;
 var  fileType =  filename['name'].split('.').pop();
 const acceptFiles=  imgType.includes(fileType)
return acceptFiles;
};

export const CheckImageExtensionAll = (filename) => {
  const imgType = ['txt', 'docx', 'pdf','apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp','mp4', 'wewbm', 'ogg'] ;
  var  fileType =  filename['name'].split('.').pop();
  const acceptFiles=  imgType.includes(fileType)
 return acceptFiles;
 };


export const CheckAudioExtension = (filename) => {
 const imgType = ['mp3'] ;
 var  fileType =  filename['name'].split('.').pop();
 const acceptFiles=  imgType.includes(fileType)
return acceptFiles;
};
export const CheckVideoExtension = (filename) => {
  const imgType = ['mp4'] ;
  var  fileType =  filename['name'].split('.').pop();
  const acceptFiles=  imgType.includes(fileType)
 return acceptFiles;
 };