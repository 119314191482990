import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowLeft from "../Global/ArrowLeft";
import ArrowRight from "../Global/ArrowRight";
import { apiGetAuth } from "./../../auth/APIRequests";
import { ENDPOINTS } from "./../../auth/EndPoints";
import Zoom from "react-reveal/Zoom";

import Fade from "react-reveal/Fade";

const OurClassesLending = () => {
  const [all, setAll] = React.useState([]);
  React.useEffect(() => {
    getAll();
  }, []);

  function getAll() {
    apiGetAuth(
      ENDPOINTS.TrainThePainAll,
      (res) => {
        setAll(res.result);
        console.log(res.result, "pppp");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <div className="lending-classes">
      <Container fluid className="mt-5 mb-5 p-0">
        <Container className="pt-5">
          <Row>
            <Col
              md={12}
              lg={12}
              className="advance-classes position-relative text-center"
            >
             
             <div className='position-relative heading-arrow-top' onClick={() => { window.location.href = '/services' }}>
              <ArrowRight colors="#00708e" />

              <div
                href="/services"
                className="text-ghatya blinking"
              >
               
               <Zoom duration={1800} right>
                  <h2 className="">T</h2>
                </Zoom>
                <Zoom duration={2000} right>
                  <h2 className="">r</h2>
                </Zoom>
                <Zoom duration={2200} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={2400} right>
                  <h2 className="">i</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className="">n</h2>
                </Zoom>
                <Zoom duration={2600} right>
                  <h2 className=""></h2>
                </Zoom>
                <Zoom duration={2800} right>
                  <h2 className="">T</h2>
                </Zoom>
                <Zoom duration={3000} right>
                  <h2 className="">h</h2>
                </Zoom>
                <Zoom duration={3200} right>
                  <h2 className="">e</h2>
                </Zoom>
                <Zoom duration={3200} right>
                  <h2 className=""></h2>
                </Zoom>
                <Zoom duration={3400} right>
                  <h2 className="">P</h2>
                </Zoom>
                <Zoom duration={3600} right>
                  <h2 className="">a</h2>
                </Zoom>
                <Zoom duration={3800} right>
                  <h2 className="">i</h2>
                </Zoom>
                <Zoom duration={4000} right>
                  <h2 className="">n</h2>
                </Zoom>
              </div>
              <ArrowLeft colors="#00708e" />

            </div>

              <hr className="hr-centers border-white" />
              <p className="text-white">
                If you suffer from Chronic Pain but still have a desire to be
                fit and active, then Bred Winner Fitness’ Train The Pain Program
                might be just what the doctor ordered. With formal education
                ranging from degrees and certifications in Sports Medicine,
                Pre-Physical Therapy, Occupational Therapy, Personal Training,
                Fitness Instruction, and Online Coaching; Bred Winner Trainers
                use their wealth of knowledge and resources to create and design
                One-of-a-kind “Therapeutic Fitness Routines”.
              </p>
            </Col>
          </Row>
        </Container>

        <Row className="no-gutters mt-5">
          {all.map((item, index) => (
            <Col
              sm={12}
              md={6}
              lg={4}
              className="advance-classes position-relative"
            >
              <div className="fitness-boxes">
                <Fade left>
                  <img src={ENDPOINTS.BaseUrl + item.key_value} />
                  {/* <img src={C1} alt="img" /> */}
                </Fade>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default OurClassesLending;
