import React, { useState } from "react";
import { Card, Container, CardBody, Col, Row } from "reactstrap";
import Nav from "react-bootstrap/Nav";
import DetailHeader from "components/Headers/DetailHeader.js";
import Tranning from "./../../assets/images/traning.webp";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

import {
  ConvertDateStandard,
  ConvertDateinToTime,
} from "../../auth/DateConverions";

const UserSessionDetails = (props) => {
  document.title = "Session Details";

  const [showSnack, setSnack] = useState(false);

  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [all, setAll] = useState([]);

  React.useEffect(() => {
    getAll();
  }, []);

  function getAll() {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.AllSessionBySessionIdForUser + props.match.params.id,
      (res) => {
        setAll(res.Result);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  }

  return (
    <>
      <DetailHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col md={12} className="mb-3">
            <Nav.Link
              href={all.length > 0 ? all[0].zoon_link : ""}
              className=" p-2 btn btn-primary d-inline float-right text-white"
            >
              Zoom Link
            </Nav.Link>
          </Col>
        </Row>
      </Container>
      {isLoading && <Loader />}

      <Container className="mt-5" fluid>
        <Row className="mt-4">
          {all.length > 0 &&
            all
              .filter((items) => items.video_path !== "null")
              .map((item, index) => (
                <Col lg={3} key={index} md={4} sm={6} className="mb-4" xs={12}>
                  <Card>
                    <CardBody className="bodies">
                      <h2 className="session-names text-center mb-2 text-white">
                        {ConvertDateStandard(item.start_date)}{" "}
                      </h2>
                      <h5 className="text-center mb-0 text-white">
                        {ConvertDateinToTime(item.start_date)} To{" "}
                        {ConvertDateinToTime(item.end_Date)}
                      </h5>
                      <div>
                        <video
                          controls
                          className="videos-sessions"
                          poster={Tranning}
                        >
                          <source
                            src={ENDPOINTS.BaseUrl + item.video_path}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          {all.length < 1 && <div className="no-record"> Record Found</div>}
        </Row>
      </Container>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default UserSessionDetails;
