import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
  Media,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateHealthAndBeauty from "components/Forms/CreateHealthAndBeauty";
import UpdateHealthAndBeauty from "components/Forms/UpdateHealthAndBeauty";
import DeleteHealthAndBeauty from "components/Forms/DeleteHealthAndBeauty";

import Loader from "components/Global/Loader";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const HealthAndBeautyAdmin = () => {
  document.title = "Health And Beauty";
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});
  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (itemId) => {
    console.log(itemId);
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleHealthAndBeauty + itemId,
      (res) => {
        setSingleItem(res.result);
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllHealthAndBeauty,
      (res) => {
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  };

  return (
    <>
      {isLoading && <Loader />}
      <CreateHealthAndBeauty
        showModal={showCreateModal}
        cloaseModal={() => setCreateModal(false)}
        reLoadData={() => getAll()}
      />
      {loadUpdateModal && (
        <UpdateHealthAndBeauty
          showModalUpdate={showUpdateModal}
          reLoadData={() => getAll()}
          cloaseModal={() => setUpdateModal(false)}
          ItemDetail={singleItem}
        />
      )}

      <DeleteHealthAndBeauty
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />

      <Container className="mt-5" fluid>
        {all.length < 3 && (
          <Row>
            <Col md={12} className="text-right mb-3">
              <button onClick={() => setCreateModal(true)} className="add-btn">
                Add
              </button>
            </Col>
          </Row>
        )}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Health And Beauty Images</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Image Title</th>
                      <th> Photo</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{item.fileText}</td>
                          <td>
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt={item.className}
                                  src={ENDPOINTS.BaseUrl + "" + item.filePath}
                                  className="avatar-custom"
                                />
                              </a>
                            </Media>
                          </td>

                          <td>
                            <button
                              className="edit-btn"
                              onClick={() => getDetail(item.id)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              className="delete-btn"
                              onClick={() => deleteEvent(item)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default HealthAndBeautyAdmin;
