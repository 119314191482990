import React, { useState, useEffect, useContext } from "react";
import { Table, Container } from "reactstrap";
import Header from "components/Headers/Header.js";
import { apiGetAuth, apiPut } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertDateTime, MaxDateValidation } from "../../auth/DateConverions";
import Loader from "../../components/Global/Loader";
import NotificationPopup from "../../components/Global/NotificationPopup";

const GroupEmailHistory = () => {
  const [all, setAll] = useState([]);
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [groupUniqueId, setGroupUniqueId] = useState(0);

  useEffect(() => {
    var urd = document.URL;
    var afterLastSlash = urd.substring(urd.lastIndexOf("/") + 1);
    setGroupUniqueId(afterLastSlash);
    getAll(afterLastSlash);
  }, []);

  const UpdateEmailStatus = (item) => {
    setIsLoading(true);
    const body = {
      group_id: groupUniqueId,
    };

    apiPut(
      ENDPOINTS.UpdateStatusEmailGroup,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("update successfully!");
        setIsLoading(false);
        getAll(groupUniqueId);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setIsLoading(false);
        console.log(error);
        getAll(groupUniqueId);
      }
    );
  };

  const getAll = async (pageNumber) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.AllEmailGroup + pageNumber,
      (res) => {
        setAll(res.result);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  return (
    <>
      <Header />
      <div className="shadow-main">
        <Container className="mt--7" fluid>
          <h3 className="mb-3 pt-3">Group History</h3>

          <div className="check-status-pendings">
            {all.length > 0 && all[0].is_complete ? (
              <button disabled className="btn btn-success">
                Done
              </button>
            ) : (
              <button onClick={UpdateEmailStatus} className="btn btn-danger">
                Pending
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Email </th>
                    <th>Invite Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {all.length > 0 && (
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            {item.first_name} {item.last_name}
                          </td>
                          <td>{item.email} </td>
                          <td>{ConvertDateTime(item.createdAt)}</td>
                          <td>
                            <p className="">
                              {item.is_complete ? "Done" : "pending"}
                            </p>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                )}
              </Table>
            </div>

            {all.length < 1 && (
              <div className="no-record col-12 mb-4"> Record Found</div>
            )}
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}

      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default GroupEmailHistory;
