import React from 'react'
import { FaAngleLeft } from "react-icons/fa";


const ArrowLeft = ({colors}) => {
  return (
    <div className="arrow-left-com">
       <span class="arrow-left arrow-sliding-left delay1" style={{ color: colors }}><FaAngleLeft /></span>  
       <span class="arrow-left arrow-sliding-left delay2" style={{ color: colors }}><FaAngleLeft /></span>  
       <span class="arrow-left arrow-sliding-left delay3" style={{ color: colors }}><FaAngleLeft /></span>  

  </div>
  )
}

export default ArrowLeft