import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Col, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateServices from "components/Forms/CreateServices";
import UpdateServices from "components/Forms/UpdateServices";
import DeleteServices from "components/Forms/DeleteServices";
import Modal from "react-bootstrap/Modal";
import CoachingOnline from "./../../components/Global/CoachingOnline";
import Loader from "components/Global/Loader";
import { apiGetAuth, apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const Services = () => {
  document.title = "Spa services";
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});

  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleServices + itemId,
      (res) => {
        setSingleItem(res.result);
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  function getAll() {
    apiGet(
      ENDPOINTS.AllServices,
      (res) => {
        console.log(res.Result, "songs");
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}

      <CreateServices
        showModal={showCreateModal}
        cloaseModal={() => setCreateModal(false)}
        reLoadData={() => getAll()}
      />
      {loadUpdateModal && (
        <UpdateServices
          showModalUpdate={showUpdateModal}
          reLoadData={() => getAll()}
          cloaseModal={() => setUpdateModal(false)}
          ItemDetail={singleItem}
        />
      )}

      <DeleteServices
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />

      <Container className="mt--7" fluid>
        <Row>
          <Col md={12} className="text-right mb-3">
            <button onClick={() => setCreateModal(true)} className="add-btn">
              Add
            </button>
          </Col>
        </Row>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Services tables</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Service Name</th>
                      <th>Service Charges</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{item.serviceName}</td>
                          <td>${item.serviceAmount}</td>
                          <td>
                            <button
                              className="edit-btn"
                              onClick={() => getDetail(item.id)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              className="delete-btn"
                              onClick={() => deleteEvent(item)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>

    </>
  );
};

export default Services;
