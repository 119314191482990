import React, { useState, createContext } from "react";
import { Card, CardHeader, Table, Container, Col, Row } from "reactstrap";
import Button from "react-bootstrap/Button";
import Header from "components/Headers/Header.js";
import Loader from "components/Global/Loader";
import Form from "react-bootstrap/Form";
import { apiGetAuth, apiGet, apiPost } from "./../auth/APIRequests";
import { ENDPOINTS } from "./../auth/EndPoints";
import { CheckImageExtensionAll } from "./../auth/DateConverions";
import NotificationPopup from "components/Global/NotificationPopup";
import MediaUploadList from "../components/Global/MediaUploadList";

const BioData = createContext();

const Index = () => {
  document.title = "Library";
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [profileCategories, setProfileCategories] = useState([]);

  const [all, setAll] = useState([]);

  React.useEffect(() => {
    getAllCategry();
  }, []);

  const getAllCategry = () => {
    setIsLoading(true);
    apiGet(
      ENDPOINTS.getAllCategory,
      (res) => {
        setProfileCategories(res.result);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const checkFilesExtension = (e) => {
    const fileStatus = CheckImageExtensionAll(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("ext-input").value = null;
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("Only Accept given files");
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.UploadCategoryMedia,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("upload media Successfully!");
        document.getElementById("upload-media-from").reset();
        setIsLoading(false);
        window.location.reload();
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-md-12 ">
            <Form
              id="upload-media-from"
              className="media-upload-form"
              onSubmit={handleSubmit}
            >
              <h3 className="mb-4 mt-2">upload media</h3>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="media_title"
                  placeholder="Title"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Select Category</Form.Label>
                <select required name="category_id" className="form-control">
                  {profileCategories.length > 0 &&
                    profileCategories.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select file</Form.Label>
                <Form.Control
                  required
                  type="file"
                  name="file"
                  id="ext-input"
                  placeholder="select file"
                  onChange={(e) => checkFilesExtension(e)}
                />
                <p className="file-extensions-names">
                  file extension allow (txt, docx, pdf,apng, gif, jpg, jpeg,png,
                  svg, webp,mp4, wewbm, ogg)
                </p>
              </Form.Group>

              <div className="text-right">
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </div>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Package media</h3>
              </CardHeader>
              <BioData.Provider value={updateData}>
                <MediaUploadList />
              </BioData.Provider>
            </Card>
          </div>
        </Row>
      </Container>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default Index;
export { BioData };
