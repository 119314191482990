import React, { useState, useEffect, useContext } from "react";
import { Table, Container } from "reactstrap";
import Header from "components/Headers/Header.js";
import { apiGet, apiGetAuth, apiPut, apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertDateTime } from "../../auth/DateConverions";
import Loader from "../../components/Global/Loader";
import NotificationPopup from "../../components/Global/NotificationPopup";
import Pagination from "react-js-pagination";

const EmailHistory = () => {
  document.title = "history";

  const [pageNumber, setPageNumber] = useState(1);
  const [countRecord, setCountRecord] = useState(1);
  const [curentPage, setCurentPage] = useState(1);

  const [isGroup, setIsGroup] = useState(false);

  const [all, setAll] = useState([]);
  const [profileCategories, setProfileCategories] = useState([]);
  const [packagesCategories, setPackagesCategories] = useState([]);

  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllCategry();
    getAllPackages();
    getAll(pageNumber);
  }, []);

  const getAllCategry = async () => {
    apiGet(
      ENDPOINTS.getAllCategory,
      (res) => {
        setProfileCategories(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllPackages = (id) => {
    apiGetAuth(
      ENDPOINTS.AllPackage,
      (res) => {
        console.log(res.Result);
        setPackagesCategories(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAll = async (pageNumber) => {
    const objdata = new FormData(document.getElementById("history-filter"));
    setIsLoading(true);
    apiPost(
      ENDPOINTS.AllEmailHistory + pageNumber,
      objdata,
      (res) => {
        console.log(res.result);
        setCountRecord(res.result.count);
        setAll(res.result.rows);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const UpdateEmailStatusGroup = (item) => {
    setIsLoading(true);
    const body = {
      group_id: item,
    };

    apiPut(
      ENDPOINTS.UpdateStatusEmailGroup,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("update successfully!");
        setIsLoading(false);
        getAll(pageNumber);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setIsLoading(false);
        console.log(error);
        getAll(pageNumber);
      }
    );
  };

  const UpdateEmailStatus = (item) => {
    setIsLoading(true);
    const body = {
      id: item.id,
      is_complete: "true",
    };

    apiPut(
      ENDPOINTS.UpdateStatusEmail,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("update successfully!");
        setIsLoading(false);
        getAll(curentPage);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const ondateChange = async (date) => {
    getAll(pageNumber);
  };

  const changePagination = (page) => {
    setCurentPage(page);
    getAll(page);
  };

  const emailChange = (emails) => {
    getAll(pageNumber);
  };

  const groupChange = (id) => {
    id === "1" ? setIsGroup(true) : setIsGroup(false);
    getAll(pageNumber);
  };

  const packageChange = async (id) => {
    getAll(pageNumber);
  };

  return (
    <>
      <Header />
      <div className="shadow-main">
        <Container className="mt--7" fluid>
          <h3 className="mb-0 pt-3">History </h3>
          <form className="row" id="history-filter">
            <div className="col-md-3 col-sm-6 mb-4 sort-by-filters-invites">
              <label>Sort by status</label>
              <select
                className="form-control"
                name="is_complete"
                onChange={(e) => emailChange(e.target.value)}
              >
                <option value="">all status</option>
                <option value="true">done</option>
                <option value="false">pending</option>
              </select>
            </div>

            <div className="col-md-3 col-sm-6 mb-4 sort-by-filters-invites">
              <label>Sort by Group</label>
              <select
                className="form-control"
                name="category_id"
                onChange={(e) => groupChange(e.target.value)}
              >
                <option value="">all groups</option>
                {profileCategories.length > 0 &&
                  profileCategories
                    .map((item, index) => (
                      <option key={index} value={item.id}>
                        {item?.name}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-md-3 col-sm-6 mb-4 sort-by-filters-invites">
              <label>all packages</label>
              <select
                className="form-control"
                name="package_id"
                onChange={(e) => packageChange(e.target.value)}
              >
                <option value="">select package</option>
                {packagesCategories.length > 0 &&
                  packagesCategories.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item?.name} / {item?.profile?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 col-sm-6 mb-4 sort-by-filters-invites">
              <label>session date</label>
              <input
                type="date"
                name="only_date"
                className="form-control"
                onChange={(e) => ondateChange(e.target.value)}
              />
            </div>
          </form>

          <div className="row">
            <div className="col-12">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {!isGroup && <th>Name</th>}

                    <th>{isGroup ? "Package" : "Email"} </th>
                    <th>Invite Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {all.length > 0 && (
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          {!isGroup && (
                            <td>
                              {" "}
                              {item?.first_name} {item?.last_name}
                            </td>
                          )}
                          <td>{isGroup ? item.package.name : item.email} </td>
                          <td>{ConvertDateTime(item.createdAt)}</td>
                          <td>
                            {!isGroup && (
                              <div>
                                {item.is_complete ? (
                                  <button disabled className="btn btn-success">
                                    Done
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => UpdateEmailStatus(item)}
                                    className="btn btn-danger"
                                  >
                                    pending{" "}
                                  </button>
                                )}
                              </div>
                            )}
                            {isGroup && (
                              <div>
                                {item.is_complete ? (
                                  <button disabled className="btn btn-success">
                                    Done
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => UpdateEmailStatusGroup(item)}
                                    className="btn btn-danger"
                                  >
                                    pending
                                  </button>
                                )}
                                <a
                                  href={"/admin/group-details/" + item.group_id}
                                  className="btn btn-success"
                                >
                                  View
                                </a>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                )}
              </Table>
            </div>

            {all.length < 1 && (
              <div className="no-record col-12 mb-4"> Record Found</div>
            )}
          </div>
          <div className="row">
            <div className="pagination-boxes col-12">
              <Pagination
                activePage={curentPage}
                itemsCountPerPage={12}
                totalItemsCount={countRecord}
                itemClass="custom-paginations"
                pageRangeDisplayed={5}
                onChange={changePagination}
              />
            </div>
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}

      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default EmailHistory;
