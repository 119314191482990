/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LifterGir2 from "../../assets/images/video-thumnail.webp";
import { apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import VideoPlayer from "../Front/components/VideoPlayer/VideoPlayer";
import AOS from "aos";
import song5 from "../../assets/audio/instrumental/ICanDoBetter-Instrumental.mp3";
import imgs from "../../assets/images/songs.webp";
import songsIcon from "../../assets/images/songs.webp";

import ReactJkMusicPlayer from "react-jinke-music-player";
const DoYoga = () => {
  const [videoModal, setVideoModal] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);
  const [videoPath, setVideoPath] = useState("");
  const [all, setAll] = useState([]);
  const [audioList1, setAllSong] = useState([
    {
      name: "I Can Do Better- Instrumental",
      singer: "I Can Do Better- Instrumental",
      cover: imgs,
      musicSrc: song5,
    },
  ]);

  const options = {
    // audio lists model
    audioLists: audioList1,
    autoPlay: true,
    toggleMode: true,
    mode: "full",
    remove: false,
    showThemeSwitch: false,
    showDownload: false,
    showPlayMode: false,
    showLyric: false,
    responsive: true,
    showPlay: true,
    showReload: false,
    preload: false,
    glassBg: true,
    drag: false,
    seeked: false,
    showMiniModeCover: false,
    showMiniProcessBar: false,
    showProgressLoadBar: false,
    showMediaSession: false,
  };

  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  React.useEffect(() => {
    getAll();
    getAllSong();
  }, []);

  const getAll = () => {
    apiGet(
      ENDPOINTS.AllEVENTSVIDEO,
      (res) => {
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllSong = () => {
    apiGet(
      ENDPOINTS.AllHomeTracks,
      (res) => {
        if (res.Result.length > 0) {
          res.Result.forEach((item) => {
            setAllSong([
              {
                name: item.trackName,
                singer: "song",
                cover: songsIcon,
                musicSrc: ENDPOINTS.BaseUrl + item.trackSource,
              },
            ]);
          });
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <>
      <Container fluid className="yoga-back position-relative">
        <Row>
          <Col md={12} sm={12} className="mb-3">
            <div className="text-center mt-5">
              <h1 className="text-white do-yoga">Events Gallery</h1>
            </div>
          </Col>
        </Row>
        <Container className="events-blocks">
          <Row>
            {all.length > 0 &&
              all.map((item, index) => (
                <Col key={index} md={4} sm={12} className="mb-3">
                  <div
                    className="lifter-girl-img"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <img
                      src={LifterGir2}
                      onClick={() => {
                        setVideoModal(true);
                        setVideoPath(ENDPOINTS.BaseUrl + item.videoPath);
                        audioInstance.pause();
                      }}
                      className="checking"
                      alt=" 545 45"
                    />
                    <h4 className="text-center">{item.eventName}</h4>
                  </div>
                </Col>
              ))}
          </Row>
          <VideoPlayer
            ClosoVidoeModal={() => {
              {
                setVideoModal(false);
              }
            }}
            showPlayer={videoModal}
            videoPath={videoPath}
          />
        </Container>
      </Container>

      <div className="listed">
        <ReactJkMusicPlayer
          {...options}
          getAudioInstance={(instance) => {
            setAudioInstance(instance);
          }}
        />
        ,
      </div>
    </>
  );
};

export default DoYoga;
