import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Col, Row } from "reactstrap";
// import Header from "components/Headers/Header.js";
import CreateUpcommingEvent from "components/Forms/CreateUpcommingEvent";
import UpdateUpcommingEvents from "components/Forms/UpdateUpcommingEvents";
import DeleteUpcommingEvents from "components/Forms/DeleteUpcommingEvents";
import Loader from "components/Global/Loader";
import CustomTooltip from "components/Global/CustomTooltip";

import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertYearMonthDate } from "../../auth/DateConverions";

const UpCommingEvent = () => {
  // document.title = "Upcoming Event";
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});

  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (item) => {
    setSingleItem(item);
    setUpdateModal(true);
    setLoadUpdateModal(true);
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllUpcommingEvents,
      (res) => {
        setAll(res.result);
        console.log(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteEvent = (item) => {
    console.log(item, "dddddddddd");
    setDeleteModal(true);
    setDeleteItem(item);
  };

  return (
    <>
      {isLoading && <Loader />}

      <CreateUpcommingEvent
        showModal={showCreateModal}
        cloaseModal={() => setCreateModal(false)}
        reLoadData={() => getAll()}
      />
      {loadUpdateModal && (
        <UpdateUpcommingEvents
          showModalUpdate={showUpdateModal}
          reLoadData={() => getAll()}
          cloaseModal={() => setUpdateModal(false)}
          ItemDetail={singleItem}
        />
      )}

      <DeleteUpcommingEvents
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />

      <Container className="mt-5" fluid>
        <Row>
          <Col md={12} className="text-right mb-3">
            <button onClick={() => setCreateModal(true)} className="add-btn">
              Add Upcoming events
            </button>
          </Col>
        </Row>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Upcoming events</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>#</th>
                      <th>title</th>
                      <th>content</th>
                      <th>event date</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td className="event-avatars">
                            <img
                              src={ENDPOINTS.BaseUrl + "" + item.image_path}
                              alt="file"
                            />
                          </td>
                          <td>{item.title}</td>
                          <td className="content-td">
                            <CustomTooltip descriptionPro={item?.content} />
                          </td>
                          <td>{ConvertYearMonthDate(item.date)}</td>
                          <td>
                            <button
                              className="edit-btn"
                              onClick={() => getDetail(item)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              className="delete-btn"
                              onClick={() => deleteEvent(item)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UpCommingEvent;
