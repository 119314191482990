import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import NotificationPopup from "components/Global/NotificationPopup";
import Loader from "components/Global/Loader";
import Header from "../../components/Front/components/Header/Header";
import { apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { FaTimes } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ThankYou = () => {
  document.title = "Thank You";
  const [showSnack, setSnack] = useState(false);
  const [additionalBuy, setAdditionalBuy] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const ids = queryParams.get("paymentId");
  const queryString = window.location.search;
  const handleDownload = async (src) => {
    await saveAs(src);
  };
  useEffect(() => {
    var formValue = JSON.parse(localStorage.getItem("data-forms"));
    formValue.payment_id = ids;
    setTimeout(function () {
      if (formValue) {
        setIsLoading(true);
        if (formValue.payment_type === "paypal") {
          paymentSucssessPaypal();
        }
        if (formValue.payment_type === "stripe") {
          AddPaymentHistory(formValue);
        }
      }
    }, 1000);
  }, []);

  const paymentSucssessPaypal = () => {
    const body = {};
    apiPost(
      ENDPOINTS.AddpayPalPaymentSucssess + queryString,
      body,
      (res) => {
        var formValue = JSON.parse(localStorage.getItem("data-forms"));
        AddPaymentHistory(formValue);
        console.log(res);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error while payment");
      }
    );
  };

  const AddPaymentHistory = async (formValue) => {
    apiPost(
      ENDPOINTS.AddAgentsPayment,
      formValue,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        if (formValue.payment_for === "appointment") {
          setSnackMsg("Add appointment Successfully!");
        }
        if (formValue.payment_for === "register") {
          setSnackMsg("Successfully register!");
        }
        if (formValue.payment_for === "sessions") {
          setSnackMsg("Successfully register for sessions!");
        }
        if (formValue.payment_for === "packages") {
          setSnackMsg("Successfully register for packages!");
        }
        if (formValue.payment_for === "videos_buy") {
          setSnackMsg("Successfully buy library!");
        }
        if (formValue.payment_for === "sessions_users") {
          setSnackMsg("Successfully buy!");
          setAdditionalBuy(true);
        }
        if (formValue.payment_for === "track-download") {
          setSnackMsg("track will download automatically");
          handleDownload(formValue.tracksrc);
        }
        setIsLoading(false);

        localStorage.removeItem("data-forms");
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setIsLoading(false);
        console.log(error);
      }
    );
  };
  return (
    <div>
      <Header className="mb-5" />
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="mb-4 lead">Thank you</div>
              <a href="/" className="btn btn-link">
                Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
      <Modal className="buy-additional-services" show={additionalBuy}>
        <Modal.Body>
          <button
            onClick={() => {
              setAdditionalBuy(false);
            }}
            className="close-btn-modal"
          >
            <FaTimes />
          </button>

          <p className="thankyou-des">
            <span>
              <FaBell />
            </span>{" "}
            do you want to buy additional service ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAdditionalBuy(false);
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => (window.location.href = "/library")}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThankYou;
