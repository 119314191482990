import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

const CustomTooltip = ({ descriptionPro }) => {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  return (
    <>
      <Button ref={target} onClick={() => setShow(!show)}>
        <span className="services-para">{descriptionPro}</span>
      </Button>
      <Overlay placement="top" target={target.current} show={show}>
        {(props) => (
          <Tooltip id="overlay-example" className="description-tooltip" {...props}>
            {descriptionPro}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default CustomTooltip;
