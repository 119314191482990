// reactstrap components
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { apiPostWithoutAuth, apiPost, apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";

const Register = () => {
  const [showPass, setShowPass] = useState(false);
  React.useEffect(() => {}, []);

  document.title = "Register";
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    var registers = document.getElementById("register-forms");
    const data = new FormData(registers);
    apiPostWithoutAuth(
      ENDPOINTS.CreateUser,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Register Successfully!");
        loginUser();
        setIsLoading(false);
        window.location.href = "/auth/login";
      },
      (error) => {
        console.log(error.response);
        if (error.response.status === 422) {
          setSnackMsg(error.response.data.message);
        } else {
          setSnackMsg("there is an error");
        }
        setSnack(true);
        setSnackColor("bg-danger");

        console.log(error);
        setIsLoading(false);
      }
    );
  };
  const loginUser = () => {
    const body = {
      email: emailEl.current.value,
      password: passwordEl.current.value,
    };
    apiPost(
      ENDPOINTS.UserLogin,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Login Successfully!");
        localStorage.setItem("accessToken", res.accessToken);
        localStorage.setItem("userId", res.userId);
        localStorage.setItem("userEmail", res.userEmail);
        localStorage.setItem("role", res.role);
        localStorage.setItem("firstName", res.firstName);
        localStorage.setItem("lastName", res.lastName);
        setIsLoading(true);
        if (parseInt(res.role) === 1) {
          window.location.href = "/admin/index";
        } else {
          window.location.href = "/";
        }
      },
      (error) => {
        setSnack(true);

        setSnackColor("bg-danger");
        setSnackMsg("User name and password wrong");
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Col lg="6" md="8">
        <div className="bg-login sign-up-form">
          <div className="text-center text-muted mb-4">
            <small className="text-black sign-ups-text">
              sign up with credentials
            </small>
          </div>
          <Form onSubmit={handleSubmit} id="register-forms">
            <Form.Group className="mb-3">
              <Form.Label className="text-black">Fisrt Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="firstName"
                placeholder="Fisrt Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-black">Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="lastName"
                placeholder="Last Name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-black">Email address</Form.Label>
              <Form.Control
                required
                type="email"
                ref={emailEl}
                name="email"
                placeholder="Email"
              />
            </Form.Group>

            <Form.Group className="mb-3 position-relative">
              <Form.Label className="text-black">Password</Form.Label>
              <Form.Control
                required
                type={showPass ? "text" : "password"}
                name="password"
                ref={passwordEl}
                placeholder="Password"
              />
              {showPass ? (
                <span
                  onClick={() => setShowPass(false)}
                  className="icon-styles"
                >
                  <FaRegEye />
                </span>
              ) : (
                <span onClick={() => setShowPass(true)} className="icon-styles">
                  <FaRegEyeSlash />
                </span>
              )}
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Sign Up
              </Button>
            </div>
          </Form>
        </div>
      </Col>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default Register;
