import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Col, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import UpdateUser from "components/Forms/UpdateUser";
import DeleteUser from "components/Forms/DeleteUser";
import Loader from "components/Global/Loader";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const Users = () => {
  document.title = "Users";
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});

  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (item) => {
    setUpdateModal(true);
    setLoadUpdateModal(true);
    setSingleItem(item);
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllUSers,
      (res) => {
        console.log(res.Result, "res.Result");
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}

      {loadUpdateModal && (
        <UpdateUser
          showModalUpdate={showUpdateModal}
          reLoadData={() => getAll()}
          cloaseModal={() => setUpdateModal(false)}
          ItemDetail={singleItem}
        />
      )}

      <DeleteUser
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />

      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">User tables</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.email}</td>
                          {/* <td>{item.user_packges.name}</td> */}
                          <td>
                            <button
                              className="edit-btn"
                              onClick={() => getDetail(item)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              className="delete-btn"
                              onClick={() => deleteEvent(item)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
