import React, { useState } from "react";
import Footer from "../../components/Front/components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import aboutImg from "../../assets/images/cc-2.webp";
import aboutImg2 from "../../assets/images/cc-4.webp";
import aboutImg5 from "../../assets/images/lll-1.webp";
import Form from "react-bootstrap/Form";
import CheckoutFormStripe from "components/Global/CheckoutFormStripe";
import PaypalForm from "../../components/Global/PaypalForm";
import { FaArrowRight } from "react-icons/fa";
import { orderBy } from "lodash";
import { apiGetAuth, apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { MaxDateValidation } from "../../auth/DateConverions";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import Header from "../../components/Front/components/Header/Header";
const VideosSessions = () => {
  document.title = "library";

  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");

  const role = localStorage.getItem("role");
  const userEmails = localStorage.getItem("userEmail");
  const lastName = localStorage.getItem("lastName");
  const firstName = localStorage.getItem("firstName");

  const oflineFormData = JSON.parse(localStorage.getItem("ofline-form-data"));
  const [oflineForm, setOflineForm] = useState(oflineFormData);

  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentType, setPaymentType] = useState("stripe");
  const [serviceType, setServiceType] = useState("1");
  const [amounts, setAmounts] = useState(0);
  const [emails, setEmails] = useState("");
  const [formDatas, setformDatas] = useState({});
  const [paypalModal, setPaypalModal] = useState(false);
  const [profileCategories, setProfileCategories] = useState([]);

  const [assesmets, setAssesmets] = useState(0);
  React.useEffect(() => {
    getAllCategry();
  }, []);

  const resetForm = () => {
    document.getElementById("appointment-forms").reset();
  };

  const setCategory = (e) => {
    setAmounts(e.target.options[e.target.selectedIndex].getAttribute("price"));
  };

  const getAllCategry = () => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.getAllCategory,
      (res) => {
         let sorted = orderBy(res.result, ['price'],['asc']); 
        setProfileCategories(sorted);
        if (res.result.length > 0) {
          setAmounts(res.result[0].price);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);

        console.log(error);
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var oflineForm = new FormData(event.target);

    if (role === "1" || role === "2") {
      let id = parseInt(document.getElementById("category_id").value);
      const body = {
        source_id: id,
      };
      apiPost(
        ENDPOINTS.LibraruOrderCheck,
        body,
        (res) => {
          console.log(res.result, "res.result");
          if (res.result) {
            setSnack(true);
            setSnackColor("bg-danger");
            setSnackMsg("already bought selected library");
          } else {
            var idAppontment = document.getElementById("appointment-forms");
            const data = new FormData(idAppontment);
            setformDatas(data);
            if (paymentType === "stripe") {
              localStorage.removeItem("ofline-form-data");
              setShowPaymentModal(true);
            }
            if (paymentType === "paypal") {
              localStorage.removeItem("ofline-form-data");
              setPaypalModal(true);
            }
          }
        },
        (error) => {
          console.log(error, "error");
        }
      );
    } else {
      var object = {};
      oflineForm.forEach((value, key) => (object[key] = value));
      var storeData = JSON.stringify(object);
      localStorage.setItem("ofline-form-data", storeData);

      localStorage.setItem("return_page", "/library");
      window.location.href = "/auth/login";
    }
  };

  return (
    <>
      <PaypalForm
        proPaypalModal={paypalModal}
        proPaypalModalClose={() => setPaypalModal(false)}
        formDatasPro={formDatas}
        amountsPro={amounts}
      />
      <Header />
      <Container className="aboutus-start pb-5">
        <CheckoutFormStripe
          showPaymentModalPro={showPaymentModal}
          amountsPro={amounts}
          emailsPro={emails}
          paymentTypePro={paymentType}
          serviceTypePro={serviceType}
          closeModal={() => setShowPaymentModal(false)}
          resetFormPro={resetForm}
          formDatasPro={formDatas}
          messageOnSucceess="booking appoinment successfully"
        />
        <Row className="mb-5">
          <Col md={4} sm={12} className="mt-3">
            <div className="club-per-month-box-ani">
              <img src={aboutImg5} alt="das" />
            </div>
          </Col>
          <Col md={4} sm={12} className="mt-3">
            <div className="club-per-month-box-ani">
              <img src={aboutImg} alt="das" />
            </div>
          </Col>
          <Col md={4} sm={12} className="mt-3">
            <div className="club-per-month-box-ani">
              <img src={aboutImg2} alt="das" />
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={12} sm={12} className="about-us-texts mt-4">
            <h4 className="h4-poppine-bold text-center">Buy Library</h4>
            <hr className="mt-3 hr-centers" />
          </Col>
        </Row>
        <div className="appointment-form">
          <Form onSubmit={handleSubmit} id="appointment-forms">
            <Row>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First Name"
                    className="appointment-inputs"
                    name="firstName"
                    defaultValue={
                      oflineForm != null ? oflineForm.firstName : firstName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last Name"
                    className="appointment-inputs"
                    name="lastName"
                    defaultValue={
                      oflineForm != null ? oflineForm.lastName : lastName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Formate +14155552671"
                    className="appointment-inputs"
                    name="phoneNumber"
                    min="1"
                    defaultValue={
                      oflineForm != null ? oflineForm.phoneNumber : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    onChange={(e) => setEmails(e.target.value)}
                    placeholder="Email"
                    className="appointment-inputs"
                    name="email"
                    defaultValue={
                      oflineForm != null ? oflineForm.email : userEmails
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    placeholder="Date Of birth"
                    name="dateOfBirth"
                    className="appointment-inputs"
                    max={MaxDateValidation()}
                    defaultValue={
                      oflineForm != null ? oflineForm.dateOfBirth : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select name="gender" className="appointment-inputs">
                    <option
                      value="Male"
                      selected={oflineForm?.gender === "Male" ? true : false}
                    >
                      Male
                    </option>
                    <option
                      value="Female"
                      selected={oflineForm?.gender === "Female" ? true : false}
                    >
                      Female
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Select
                    name="category_id"
                    id="category_id"
                    // value={oflineForm != null ? oflineForm.category_id : ""}
                    // value={"2"}
                    className="appointment-inputs"
                    onChange={(e) => setCategory(e)}
                  >
                    {profileCategories.length > 0 &&
                    profileCategories
                       .filter(x=>x.status === true)
                      .map((item, index) => (
                        <option
                          selected={
                            oflineForm?.category_id === item.id ? true : false
                          }
                          price={item.price}
                          key={index}
                          value={item.id}
                        >
                          {item.name} | ${item.price}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control
                    name="payment_for"
                    type="hidden"
                    value="videos_buy"
                  />
                  <Form.Control
                    required
                    type="hidden"
                    name="height"
                    value="0"
                  />
                  <Form.Control
                    required
                    type="hidden"
                    name="weight"
                    value="0"
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select PaymentType</Form.Label>
                  <Form.Select
                    name="payment_type"
                    className="appointment-inputs"
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option
                      value="stripe"
                      selected={
                        oflineForm?.payment_type === "stripe" ? true : false
                      }
                    >
                      Stripe
                    </option>
                    <option
                      value="paypal"
                      selected={
                        oflineForm?.payment_type === "paypal" ? true : false
                      }
                    >
                      Paypal
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} lg={12} className="position-relative text-center">
                <Form.Group className="mb-3 text-end">
                  <button
                    className="btn text-white books-appointment "
                    type="submit"
                  >
                    Buy Library
                    <span>
                      <FaArrowRight />
                    </span>
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <Footer className="mt-5" />
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default VideosSessions;
