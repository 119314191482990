import React, { useState, useRef } from "react";
import Footer from "components/Front/components/Footer/Footer";
import Container from "react-bootstrap/Container";
import ReactJkMusicPlayer from "react-jinke-music-player";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import aboutImg from "./../../assets/images/ss-1.webp";
import { Player, ControlBar } from "video-react";
import Modal from "react-bootstrap/Modal";
import Video1 from "./../../assets/video/video-2.mp4";
import VideoNew from "./../../assets/video/bread-video.mp4";
import overViewImg from "./../../assets/images/video-thumb.webp";
import playBtn from "./../../assets/images/play-btn.png";
import { FaTimes } from "react-icons/fa";
import Header from "components/Front/components/Header/Header";
import { apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import songsIcon from "./../../assets/images/songs.webp";
import Loader from "components/Global/Loader";
import song5 from "./../../assets/audio/instrumental/ICanDoBetter-Instrumental.mp3";
import imgs from "./../../assets/images/songs.webp";

const AboutUs = () => {
  document.title = "About Us";
  const [videoModal, setVideoModal] = useState(false);
  const playerRef = useRef(null);
  const [audioList1, setAll] = useState([
    {
      name: "I Can Do Better- Instrumental",
      singer: "I Can Do Better- Instrumental",
      cover: imgs,
      musicSrc: song5,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);

  const options = {
    // audio lists model
    audioLists: audioList1,
    autoPlay: true,
    toggleMode: true,
    mode: "full",
    remove: false,
    showThemeSwitch: false,
    showDownload: false,
    showPlayMode: false,
    showLyric: false,
    responsive: true,
    showPlay: true,
    showReload: false,
    preload: false,
    glassBg: true,
    drag: false,
    seeked: false,
    showMiniModeCover: false,
    showMiniProcessBar: false,
    showProgressLoadBar: false,
    showMediaSession: false,
  };
  React.useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    setIsLoading(true);
    apiGet(
      ENDPOINTS.AllHomeTracks,
      (res) => {
        if (res.Result.length > 0) {
          res.Result.forEach((item) => {
            setAll([
              {
                name: item.trackName,
                singer: "song",
                cover: songsIcon,
                musicSrc: ENDPOINTS.BaseUrl + item.trackSource,
              },
            ]);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  return (
    <>
      <Header />
      <Container className="aboutus-start pb-5">
        <Row>
          <Col md={6} sm={12}>
            <div className="">
              <img src={aboutImg} alt="das" />
            </div>
          </Col>
          <Col className="">
            <div className="about-us-texts mt-5">
              <h4 className="h4-poppine-bold">
                BRED WINNER FITNESS & ENTERTAINMENT
              </h4>
              <hr className="mt-3" />
              {/* <h4 className="normal-h4s">2 Much Pain 2 Train??? BRED WINNER FITNESS… WE <span className="train-the-pains">TRAIN THE PAIN!!!</span></h4>
              <h4 className="normal-h4s">Bred Winner Fitness Biography</h4> */}
              <h4 className="normal-h4s">WHY</h4>
              <p className="font-14">
                At Bred Winner Fitness our motto is{" "}
                <span className="train-the-pains">Bred 2 Win!!!</span> We
                believe that success comes from within! As a result, our highly
                skilled Certified Trainers and Licensed Therapist also
                specialize in connecting with our clients to help them discover
                and unlock The Winner that’s inbred in us all!
              </p>
              <p className="font-14">
                Unlocking that{" "}
                <span className="train-the-pains">Bred 2 Win</span> Mentality is
                critical! It provides the internal motivation and
                determination,‘Bred Winner Trainers’ will use as we safely and
                strategically guide you through a personalized routine, designed
                and customized to accomplish the fitness goals We’ll create
                together.
              </p>

              <h4 className="normal-h4s">How</h4>
              <p className="font-14">
                It all starts with the Initial Assessment. A simple, yet in
                depth process that is so often neglected or even completely
                ignored.{" "}
              </p>
              <p className="font-14">
                Every Bred Winner Fitness Client will receive an Initial
                Assessment performed by A Certified Personal Trainer or Licensed
                Therapist prior to beginning any Fitness program. When performed
                by a Certified Personal Trainer or Licensed Therapist, the
                results from the initial assessment provides the ‘building
                blocks’ for any successful training program. “With the proper
                guidance and direction, even the blind, need not see.”-Skinz.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={12} sm={12} className="mt-5">
            <h4 className="h4-poppine-bold text-center">Summary</h4>
            <hr className="green-center-hr" />
            <p className="font-14 text-center">
              At Bred Winner Fitness, our Trainers understand the effects of
              Chronic Pain as much as we understand Exercise Physiology and
              Health & Fitness. Our experience and formal education of Sports
              Medicine and Physical Therapy, is what enables us to create,
              modify, and DESIGN safe and effective exercise programs.
              Individualized programs that not only help our clients manage
              their pain, but also set and meet their weight loss and fitness
              goals, which results in them living longer, healthier and happier
              lives.
            </p>
            <h4 className="h4-poppine-bold text-center">Our Trainer</h4>
            <hr className="green-center-hr" />

            <div className="gym-overview-about mt-5">
              <img
                src={overViewImg}
                onClick={() => {
                  setVideoModal(true);
                  {
                    audioInstance.pause();
                  }
                }}
                alt="das"
              />
              <img
                src={playBtn}
                onClick={() => {
                  setVideoModal(true);
                  {
                    audioInstance.pause();
                  }
                }}
                alt="das"
                className="gym-ionc"
              />
            </div>
            <h4 className="normal-h4s text-center mt-3">Personal Biography</h4>
            <h5 className="text-center">Al’taa Jackson-CEO/Founder</h5>
            <h5 className="text-center">
              Bred Winner Fitness & Entertainment LLC{" "}
            </h5>
            <p className="font-14 text-center">
              Hi, I’m Al'taa (pronounced “owl-tay”). And like so many others,
              you can call me Coach Taa. I was born and raised in Cincinnati,
              Ohio and grew up a Die-hard Bengals fan, After completing High
              School, I attended the University of Cincinnati where I earned a
              BS Equivalent in Sports Medicine/Pre Physical Therapy & Exercise
              Physiology. I first began working as an ACE Certified Personal
              Trainer in 2003, training at various facilities such as The
              University of Cincinnati, Bally’s, Lady Fitness, 24 Hr Fitness,
              Planet Fitness & Fitworks, until 2015 when an unfortunate injury
              to my back forced me to step away from the physical aspect of
              personal training for a while.
            </p>
            <p className="font-14 text-center">
              In late 2017, after suffering from daily chronic pain mixed with
              an intense desire to return to training, I decided to have back
              surgery. An unsuccessful choice I regret. In fact, it was my own
              experience battling daily chronic pain, even after surgery, that
              inspired me to create The Bred Winner Fitness “Train The Pain”
              Program. An Individually personalized fitness program designed
              specifically for Chronic Pain sufferers! My routines blend
              Industry proven therapeutic movements with fat burning, muscle
              building routines that are individually designed and customized so
              as not to exacerbate or aggravate preexisting issues; while
              simultaneously rehabbing and strengthening to create overall total
              fitness.
            </p>
            <p className="font-14 text-center">
              Bottom line: I know it works because I do it! So let’s get started
              today and let us show you how to Train the Pain!
            </p>
            <div className="credencial-about-us-box">
              <h5 className="normal-h4s">Credentials</h5>
              <ul className="credencial-about-us pl-0">
                <li>
                  B.S. Equivalent Sports Medicine /Pre-Physical Therapy &
                  Exercise Physiology-University of Cincinnati 2007
                </li>
                <li>Certified Fitness Coach</li>
                <li>Certified Personal Trainer</li>
                <li>Online Coaching Certification </li>
                <li>CPR/First Aide & AED Certified</li>
                <li>NEXT Insured</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer className="mt-5" />
      <Modal
        show={videoModal}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="position-relative">
          <button
            onClick={() => setVideoModal(false)}
            className="clsoe-btn-modal"
          >
            <FaTimes />
          </button>
          <Player ref={playerRef} autoPlay>
            <source src={VideoNew} />
            <ControlBar autoHide={false} />
          </Player>
        </Modal.Body>
      </Modal>
      <div className="listed">
        <ReactJkMusicPlayer
          {...options}
          getAudioInstance={(instance) => {
            setAudioInstance(instance);
          }}
        />
        ,
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default AboutUs;
