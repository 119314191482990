import React from "react";
import equal from "fast-deep-equal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NotificationPopup from "../Global/NotificationPopup";
import { apiPut } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import Loader from "components/Global/Loader";

export class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackColor: "bg-primary",
      snackMsg: "",
      Id: 0,
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      IsUpdate: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({
      Id: this.props.ItemDetail.id,
      FirstName: this.props.ItemDetail.firstName,
      LastName: this.props.ItemDetail.lastName,
      Email: this.props.ItemDetail.email,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.ItemDetail.id, prevProps.ItemDetail.id)) {
      this.setState({
        Id: this.props.ItemDetail.id,
        FirstName: this.props.ItemDetail.firstName,
        LastName: this.props.ItemDetail.lastName,
        Email: this.props.ItemDetail.email,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    this.setState({ isLoading: true });

    apiPut(
      ENDPOINTS.UpdateUser,
      data,
      (res) => {
        console.log(res);
        this.setState({
          showSnack: true,
          snackMsg: "Successfully updated",
          snackColor: "bg-success",
          isLoading: false,
        });
        this.props.reLoadData();
        this.props.cloaseModal();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      },
      (error) => {
        this.setState({
          showSnack: true,
          snackMsg: "there is an error",
          snackColor: "bg-danger",
          isLoading: false,
        });
        this.props.reLoadData();
        console.log(error);
      }
    );
  }
  render() {
    return (
      <>
        <Modal show={this.props.showModalUpdate} backdrop="static">
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={this.state.FirstName}
                  onChange={(e) => this.setState({ FirstName: e.target.value })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={this.state.LastName}
                  onChange={(e) => this.setState({ LastName: e.target.value })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.Email}
                  onChange={(e) => this.setState({ Email: e.target.value })}
                />
                <Form.Control
                  required
                  type="hidden"
                  name="id"
                  value={this.state.Id}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  name="is_update"
                  value={this.state.IsUpdate}
                  label="Update Password"
                  onChange={(e) =>
                    this.setState({ IsUpdate: !this.state.IsUpdate })
                  }
                />
              </Form.Group>
              {this.state.IsUpdate === true && (
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={this.state.Password}
                    onChange={(e) =>
                      this.setState({ Password: e.target.value })
                    }
                  />
                </Form.Group>
              )}

              <div className="text-right">
                <Button variant="info" onClick={() => this.props.cloaseModal()}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Update
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <NotificationPopup
          closeSnackPro={() => this.setState({ showSnack: false })}
          showSnkPro={this.state.showSnack}
          bgColorPro={this.state.snackColor}
          snackMsgPro={this.state.snackMsg}
        />
        {this.state.isLoading && <Loader />}
      </>
    );
  }
}

export default UpdateUser;
