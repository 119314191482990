import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Header from "components/Headers/Header.js";
import Loader from "components/Global/Loader";
import NotificationPopup from "components/Global/NotificationPopup";
import { apiGetAuth, apiPut } from "../../auth/APIRequests";
import Modal from "react-bootstrap/Modal";

import { ENDPOINTS } from "../../auth/EndPoints";

const Packages = () => {
  document.title = "Packages";
  const [isLoading, setIsLoading] = useState(false);
  const [all, setAll] = useState([]);
  const [modalPcakageUpdate, setModalPcakageUpdate] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [showSnack, setSnack] = useState(false);

  const [Id, setId] = useState(0);
  const [Name, setName] = useState("");
  const [Price, setPrice] = useState(0);
  const [categoryId, setCategoryId] = useState(0);

  React.useEffect(() => {
    var urd = document.URL;
    var afterLastSlash = urd.substring(urd.lastIndexOf("/") + 1);
    setCategoryId(afterLastSlash);
    getAll(afterLastSlash);
  }, []);

  const getDetail = (item) => {
    setModalPcakageUpdate(true);
    setId(item.id);
    setName(item.name);
    setPrice(item.price);
  };
  function getAll(id) {
    apiGetAuth(
      ENDPOINTS.getPackagesByCategory + id,
      (res) => {
        console.log(res, "getPackagesByCategory");
        setAll(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.updatePackage,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("update  Successfully!");
        setIsLoading(false);
        setModalPcakageUpdate(false);
        getAll(categoryId);
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("there is an error");
        setModalPcakageUpdate(false);
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">packages</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Duration</th>
                      <th>Price</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.duration} month</td>
                          <td>${item.price}</td>
                          <td>
                            <button
                              className="btn btn-danger font-14"
                              onClick={() => getDetail(item)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>

      <Modal show={modalPcakageUpdate} backdrop="static">
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label> Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control required type="hidden" name="id" value={Id} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                required
                type="number"
                name="price"
                placeholder="Price"
                min="1"
                value={Price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <div className="text-right">
              <Button
                variant="info"
                onClick={() => setModalPcakageUpdate(false)}
              >
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default Packages;
