/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { Player, ControlBar } from "video-react";
import Modal from "react-bootstrap/Modal";
import Video1 from "../../../..//assets/video/video-1.mp4";

import { FaTimes } from "react-icons/fa";
import equal from "fast-deep-equal";

export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      VideoSrc: "",
    };
  }

  componentDidMount() {
    this.setState({
      VideoSrc: this.props.videoPath,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.videoPath, prevProps.videoPath)) {
      this.setState({
        VideoSrc: this.props.videoPath,
      });
    }
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.showPlayer}
          animation
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="position-relative">
            <button
              onClick={() => this.props.ClosoVidoeModal()}
              className="clsoe-btn-modal"
            >
              <FaTimes />
            </button>
            <Player
              ref={(player) => {
                this.player = player;
              }}
              autoPlay
            >
              <source src={this.state.VideoSrc} />
              <ControlBar autoHide={false} />
            </Player>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
