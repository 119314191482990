import React, { useState } from "react";
import Footer from "../../components/Front/components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import aboutImg from "../../assets/images/hh-1.webp";
import greenCircle from "../../assets/images/green-circle.webp";
import Form from "react-bootstrap/Form";
import NotificationPopup from "components/Global/NotificationPopup";
import CheckoutFormStripe from "components/Global/CheckoutFormStripe";
import PaypalForm from "../../components/Global/PaypalForm";
import { FaArrowRight } from "react-icons/fa";
import { apiGet } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import {
  ConvertDefaultHtmlCurremt,
  MaxDateValidation,
} from "../../auth/DateConverions";
import Header from "../../components/Front/components/Header/Header";

const HealthAndBeauty = () => {
  document.title = "Health And Beauty";
  const oflineFormData = JSON.parse(localStorage.getItem("ofline-form-data"));
  const [oflineForm, setOflineForm] = useState(oflineFormData);

  const [all, setAll] = useState([]);
  const [allServices, setServicesAll] = useState([]);
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentType, setPaymentType] = useState("stripe");
  const [serviceType, setServiceType] = useState("1");
  const [amounts, setAmounts] = useState(5);
  const [emails, setEmails] = useState("");
  const [formDatas, setformDatas] = useState({});
  const [paypalModal, setPaypalModal] = useState(false);
  const role = localStorage.getItem("role");
  const userEmail = localStorage.getItem("userEmail");
  const lastName = localStorage.getItem("lastName");
  const firstName = localStorage.getItem("firstName");

  React.useEffect(() => {
    getAll();
    getAllServices();
  }, []);

  const resetForm = () => {
    document.getElementById("appointment-forms").reset();
  };

  const SelectServices = (e) => {
    var uid =
      e.target.options[e.target.selectedIndex].getAttribute("service_amount");
    setServiceType(e.target.value);
    setAmounts(uid);
  };

  const getAll = () => {
    apiGet(
      ENDPOINTS.AllHealthAndBeautyFront,
      (res) => {
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const getAllServices = () => {
    apiGet(
      ENDPOINTS.AllServices,
      (res) => {
        setServicesAll(res.Result);
        setAmounts(res.Result[0].serviceAmount);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleSubmit = async (event) => {
    var oflineForm = new FormData(event.target);

    event.preventDefault();
    if (role === "1" || role === "2") {
      var idAppontment = document.getElementById("appointment-forms");
      const data = new FormData(idAppontment);
      setformDatas(data);
      if (paymentType === "stripe") {
        localStorage.removeItem("ofline-form-data");
        setShowPaymentModal(true);
      }
      if (paymentType === "paypal") {
        localStorage.removeItem("ofline-form-data");
        setPaypalModal(true);
      }
    } else {
      var object = {};
      oflineForm.forEach((value, key) => (object[key] = value));
      var storeData = JSON.stringify(object);

      localStorage.setItem("ofline-form-data", storeData);
      localStorage.setItem("return_page", "/health-and-beauty");
      window.location.href = "/auth/login";
    }
  };

  return (
    <>
      <PaypalForm
        proPaypalModal={paypalModal}
        proPaypalModalClose={() => setPaypalModal(false)}
        formDatasPro={formDatas}
        amountsPro={amounts}
      />
      <Header />
      <Container className="aboutus-start pb-5">
        <CheckoutFormStripe
          showPaymentModalPro={showPaymentModal}
          amountsPro={amounts}
          emailsPro={emails}
          paymentTypePro={paymentType}
          serviceTypePro={serviceType}
          closeModal={() => setShowPaymentModal(false)}
          resetFormPro={resetForm}
          formDatasPro={formDatas}
          messageOnSucceess="booking appoinment successfully"
        />
        <Row className="mb-5">
          <Col md={6} sm={12}>
            <div className="about-us-img">
              <img src={aboutImg} alt="das" />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="about-us-texts mt-5">
              {/* <h4 className="h4-poppine-bold">Al'Taa Jackson</h4> */}
              <h4 className="bena-chotiya">Byouty By Benna’</h4>
              <hr className="mt-3" />
              <h4 className="normal-h4">Byouty By Benna’</h4>
              <h5>Personal Biography</h5>
              <span>Nicole B. Hunn- CEO/Founder </span>
              <p>
                Hi my name is NIcole and I love doing nails! I’m a Licensed
                Advanced Manicurist with over 15 yrs experience in the nail
                industry. After completing my studies at Western Hills Beauty
                School in 2006, I began working at Mitchell’s Salon and Spa. In
                2014, I earned my Instructor’s License at Mitchell’s Salon which
                allowed me to assist others and hold classes on the health and
                beauty of nails. In 2020 I became certified to perform *Wellness
                Pedicures which has allowed me to safely care for those who
                manage diabetes and other conditions that effect the health of
                the feet.
              </p>
              <p>
                I have a strong background in fashion and I stay updated and
                knowledgeable on the latest industry trends, working with
                popular brands such as: CND, OPI, Jellish, and FootLogic to name
                a few. But what makes me different from others is I really
                listen and understand the needs of my clients. I develop and
                nurture long-term relationships so that my clients not only gain
                a Tech, but also a Friend.
              </p>
              <p>
                At Byouty By Benna’ we put the ‘you’ in beauty, and my passion
                is to help you discover your Byouty Within, while providing you
                with a service that will have you looking and feeling amazing
                both inside and out. I want to make your time with me a pleasant
                and relaxing break in your day.{" "}
              </p>
              <p>
                Beauty may begin with a B, but it starts with You! Book your
                session today!
              </p>
              <h4>Credentials</h4>
              <ul className="cutoms-lists">
                <li>Wellness Pedicure -NASPA School of Podology-2020</li>
                <li>Nail Instructor - Mitchell’s Salon & Day Spa -2014</li>
                <li>
                  Advanced Manicurist Ohio State Board of Cosmetology-2006
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12} className="about-us-texts mt-4">
            <h5 className="h5-poppine-bold text-center ">Services Provided</h5>
            <hr className="mt-3 hr-centers" />
          </Col>
          <Col md={3} sm={6} className=" col-6">
            <div className="beauty-servcies-img">
              <p>
                <img src={greenCircle} alt="asdsa" />
                Manicures
              </p>
            </div>
          </Col>
          <Col md={3} sm={6} className="col-6 ">
            <div className="beauty-servcies-img">
              <p>
                <img src={greenCircle} alt="asdsa" />
                Pedicures
              </p>
            </div>
          </Col>
          <Col md={3} sm={6} className=" col-6">
            <div className="beauty-servcies-img">
              <p>
                <img src={greenCircle} alt="asdsa" /> Custom Nails
              </p>
            </div>
          </Col>
          <Col md={3} sm={6} className="col-6">
            <div className="beauty-servcies-img">
              <p>
                <img src={greenCircle} alt="asdsa" /> Wellness Pedicures
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          {all.length > 0 &&
            all.map((item, index) => (
              <Col key={index} md={4} lg={4} className="mb-lg-2 mb-sm-3">
                <div className="tranding-img">
                  <img
                    src={ENDPOINTS.BaseUrl + item.filePath}
                    className="h-transUp"
                    alt="asdsa"
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={12} sm={12} className="about-us-texts mt-4">
            <h4 className="h4-poppine-bold text-center">
              Book an Appointment{" "}
            </h4>
            <hr className="mt-3 hr-centers" />
          </Col>
        </Row>
        <div className="appointment-form">
          <Form onSubmit={handleSubmit} id="appointment-forms">
            <Row>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First Name"
                    className="appointment-inputs"
                    name="firstName"
                    defaultValue={
                      oflineForm != null ? oflineForm.firstName : firstName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last Name"
                    className="appointment-inputs"
                    name="lastName"
                    defaultValue={
                      oflineForm != null ? oflineForm.firstName : lastName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Formate +14155552671"
                    className="appointment-inputs"
                    name="phoneNumber"
                    defaultValue={
                      oflineForm != null ? oflineForm.phoneNumber : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    onChange={(e) => setEmails(e.target.value)}
                    placeholder="Email"
                    className="appointment-inputs"
                    name="email"
                    defaultValue={
                      oflineForm != null ? oflineForm.email : userEmail
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    placeholder="Date Of birth"
                    name="dateOfBirth"
                    className="appointment-inputs"
                    max={MaxDateValidation()}
                    defaultValue={
                      oflineForm != null ? oflineForm.dateOfBirth : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select name="gender" className="appointment-inputs">
                    <option
                      value="Male"
                      selected={oflineForm?.gender === "Male" ? true : false}
                    >
                      Male
                    </option>
                    <option
                      value="Female"
                      selected={oflineForm?.gender === "Female" ? true : false}
                    >
                      Female
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col
                md={12}
                lg={6}
                className="position-relative mb-md-3 mb-sm-2 "
              >
                <Form.Group className="mb-3">
                  <Form.Label>Appointment Time</Form.Label>
                  <Form.Control
                    required
                    name="appointmentTime"
                    type="datetime-local"
                    placeholder="Appointment Time"
                    className="appointment-inputs"
                    min={ConvertDefaultHtmlCurremt()}
                    defaultValue={
                      oflineForm != null ? oflineForm.appointmentTime : ""
                    }
                  />
                  <Form.Control
                    name="payment_for"
                    type="hidden"
                    value="appointment"
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Label>Services</Form.Label>
                <Form.Select
                  name="services"
                  onChange={(e) => SelectServices(e)}
                  className="appointment-inputs"
                >
                  <option className="position-relative" disabled>
                    Select Service
                  </option>
                  {allServices.length > 0 &&
                    allServices.map((item, index) => (
                      <>
                        <option
                          selected={
                            oflineForm?.services === item.id ? true : false
                          }
                          className="position-relative select-services-box"
                          key={index}
                          service_amount={item.serviceAmount}
                          value={item.id}
                        >
                          {item.serviceName} ${item.serviceAmount}{" "}
                        </option>
                      </>
                    ))}
                </Form.Select>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select PaymentType</Form.Label>
                  <Form.Select
                    name="payment_type"
                    className="appointment-inputs"
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option
                      value="stripe"
                      selected={
                        oflineForm?.payment_type === "stripe" ? true : false
                      }
                    >
                      Stripe
                    </option>
                    <option
                      value="paypal"
                      selected={
                        oflineForm?.payment_type === "paypal" ? true : false
                      }
                    >
                      Paypal
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} lg={12} className="position-relative text-center">
                <Form.Group className="mb-3 text-end">
                  <button
                    className="btn text-white books-appointment "
                    type="submit"
                  >
                    Book Appointment
                    <span>
                      <FaArrowRight />
                    </span>
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <Footer className="mt-5" />

      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
    </>
  );
};

export default HealthAndBeauty;
