import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import NotificationPopup from "../../components/Global/NotificationPopup";
import { apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import Nav from "react-bootstrap/Nav";
import Loader from "components/Global/Loader";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";

const Login = () => {
  document.title = "Login";
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);
  const [showPass, setShowPass] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const body = {
      email: emailEl.current.value,
      password: passwordEl.current.value,
    };
    apiPost(
      ENDPOINTS.UserLogin,
      body,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Login Successfully!");
        localStorage.setItem("accessToken", res.accessToken);
        localStorage.setItem("userId", res.userId);
        localStorage.setItem("userEmail", res.userEmail);
        localStorage.setItem("role", res.role);
        localStorage.setItem("firstName", res.firstName);
        localStorage.setItem("lastName", res.lastName);
        setIsLoading(true);
        let returnPage = localStorage.getItem("return_page");
        if (returnPage === null) {
          if (parseInt(res.role) === 1) {
            window.location.href = "/admin/index";
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = returnPage;
        }
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Invalid credentials");
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Col lg="5" md="7">
        <div className="bg-login sign-up-form">
          <div className="text-center text-muted mb-4">
            <small className="text-black sign-ups-text">
              sign in with credentials
            </small>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="text-black mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                ref={emailEl}
                placeholder="Email"
              />
            </Form.Group>

            <Form.Group className="text-black mb-3 position-relative">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type={showPass ? "text" : "password"}
                ref={passwordEl}
                placeholder="Password"
              />
              {showPass ? (
                <span
                  onClick={() => setShowPass(false)}
                  className="icon-styles"
                >
                  <FaRegEye />
                </span>
              ) : (
                <span onClick={() => setShowPass(true)} className="icon-styles">
                  <FaRegEyeSlash />
                </span>
              )}
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit" className="mb-3">
                Log In
              </Button>
              <Nav.Link href="/auth/register" className="">
                Sign Up
              </Nav.Link>
            </div>
          </Form>
        </div>
        <NotificationPopup
          closeSnackPro={() => setSnack(false)}
          showSnkPro={showSnack}
          bgColorPro={snackColor}
          snackMsgPro={snackMsg}
        />
      </Col>
      {isLoading && <Loader />}
    </>
  );
};

export default Login;
