import React from "react";
import equal from "fast-deep-equal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NotificationPopup from "../Global/NotificationPopup";
import { apiPut } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import {
  CheckImageExtension,
  ConvertDefaultHtmlCurremt,
  MaxDateValidation
} from "../../auth/DateConverions";
import Loader from "components/Global/Loader";

export class UpdateUpcommingEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackColor: "bg-primary",
      snackMsg: "",
      Id: 0,
      Title: "",
      Content: "",
      DateEvent: "",
      ImagePath: "",
      isVideoUpload: false,
      updateImg: "false",
      EventImgPath: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({
      Id: this.props.ItemDetail.id,
      Title: this.props.ItemDetail.title,
      Content: this.props.ItemDetail.content,
      DateEvent: this.props.ItemDetail.date,
      ImagePath: this.props.ItemDetail.image_path,
    });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.ItemDetail.id, prevProps.ItemDetail.id)) {
      this.setState({
        Id: this.props.ItemDetail.id,
        Title: this.props.ItemDetail.title,
        Content: this.props.ItemDetail.content,
        DateEvent: this.props.ItemDetail.date,
        ImagePath: this.props.ItemDetail.image_path,
      });
    }
  }

  checkFilesAcceptImg = (e) => {
    if (e.target.files.length > 0) {
      this.setState({ updateImg: "true" });
    }
    const fileStatus = CheckImageExtension(e.target.files[0]);
    if (!fileStatus) {
      document.getElementById("img-input").value = null;
      this.setState({
        showSnack: true,
        snackMsg: "Accept Only .svg , .webp, .webp file",
        snackColor: "bg-danger",
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const data = new FormData(event.target);
    apiPut(
      ENDPOINTS.UpdateUpcommingEvents,
      data,
      (res) => {
        this.setState({
          showSnack: true,
          snackMsg: "Successfully updated",
          snackColor: "bg-success",
          isLoading: false,
        });
        this.props.reLoadData();
        this.props.cloaseModal();
        // eslint-disable-next-line no-restricted-globals
      },
      (error) => {
        this.setState({
          showSnack: true,
          snackMsg: "there is an error",
          snackColor: "bg-danger",
          isLoading: false,
        });
        this.props.reLoadData();
        console.log(error);
      }
    );
  }
  render() {
    return (
      <>
        <Modal show={this.props.showModalUpdate} backdrop="static">
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Event title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="title"
                  placeholder="Class Title"
                  value={this.state.Title}
                  onChange={(e) => this.setState({ Title: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Event date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  name="date"
                  min={MaxDateValidation()}
                  value={this.state.DateEvent}
                  onChange={(e) => this.setState({ DateEvent: e.target.value })}
                  placeholder="Class Title"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Event content</Form.Label>
                <textarea
                  className="form-control"
                  required
                  type="text"
                  name="content"
                  value={this.state.Content}
                  onChange={(e) => this.setState({ Content: e.target.value })}
                  placeholder="Class Title"
                ></textarea>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Chose Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  id="img-input"
                  onChange={(e) => this.checkFilesAcceptImg(e)}
                  placeholder="Client Review"
                />
              </Form.Group>
              <Form.Control
                type="hidden"
                value={this.props.ItemDetail.id}
                name="id"
              />
              <img
                src={ENDPOINTS.BaseUrl + "" + this.props.ItemDetail.image_path}
                alt="file"
                className="show-img-file mt-2"
              />
              <div className="text-right mt-3">
                <Button variant="info" onClick={() => this.props.cloaseModal()}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Update
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <NotificationPopup
          closeSnackPro={() => this.setState({ showSnack: false })}
          showSnkPro={this.state.showSnack}
          bgColorPro={this.state.snackColor}
          snackMsgPro={this.state.snackMsg}
        />
        {this.state.isLoading && <Loader />}
      </>
    );
  }
}

export default UpdateUpcommingEvents;
