import React, { useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { isUndefined } from "lodash";
import { apiGetAuth } from '../../auth/APIRequests';
import { ENDPOINTS } from '../../auth/EndPoints';
import { ConvertDateStandard, ConvertTimeStandard } from '../../auth/DateConverions';
const UserTrannings = () => {
  document.title = "Package";
  const [all, setAll] = useState([]);

  React.useEffect(() => {
    getAll();
  }, []);
  function getAll() {
    apiGetAuth(
      ENDPOINTS.UserTranings,
      (res) => {
        console.log(res.Result, "sahi hey")
        setAll(res.Result);

      },
      (error) => {
        console.log(error);
      }
    );
  }
  return (
    <>
      <UserHeader />
      {/* Table */}
      <Container className="mt--5 " fluid>
        {all.length > 0 && (
          <Row>
            {all.length && all.map((item, index) =>
              <Col className="mt-3" lg={4} md={6} sm={6} key={index} xs={12}>
                <Card >
                  <CardBody>
                    <CardTitle className="card-titles">{item.name} <span className="amount-in-card">{' $'}{item.session_all.orderData.amount}</span> </CardTitle>
                    <CardText>
                      <h4 className="mb-1">{item.session_all.orderData.firstName} {' '}  {item.session_all.orderData.lastName}</h4>
                      <p className="phone-number mb-1 font-weight-bold">category : {item.category_id.name}</p>
                      <p className="phone-number mb-1 font-weight-bold">Phone : {item.session_all.orderData.phoneNumber}</p>
                      <p className="phone-number font-weight-bold">email: {item.session_all.orderData.email}</p>
                    </CardText>
                    <p className="mb-2  btn btn-primary text-white card-datess font-14 position-relative"> <span className="date-expiry">start</span> {ConvertDateStandard(item.session_all.createdAt)}</p>
                    <p className="mb-2 ml-0  btn btn-danger text-white card-datess font-14 position-relative"> <span className="date-expiry">expire</span> {ConvertDateStandard(item.session_all.orderExpire)}</p>
                  </CardBody>
                </Card>
              </Col>
            )}

          </Row>
        )}
        {all.length < 1 && (
          <div className="no-record">  Record Found</div>
        )}
      </Container>
    </>
  );
};

export default UserTrannings;
