import React, { useState, useRef } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
  Modal,
} from "reactstrap";
import { apiGetAuth, apiPost } from "../../auth/APIRequests";
import { ReplaceUnderscores } from "../../auth/DateConverions";

import NotificationPopup from "components/Global/NotificationPopup";
import Loader from "components/Global/Loader";

import { ENDPOINTS } from "../../auth/EndPoints";

const UpdateKeyValues = () => {
  // document.title = "Events Videos";

  const [all, setAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");

  const [selectedItem, setSelectedItem] = useState({});

  React.useEffect(() => {
    getAll();
  }, []);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    apiPost(
      ENDPOINTS.EntertainmentContentAdd,
      data,
      (res) => {
        setSnack(true);
        setSnackColor("bg-success");
        setSnackMsg("Add Successfully!");
        setIsLoading(false);
        getAll();
        setSelectedItem({});
      },
      (error) => {
        setSnack(true);
        setSnackColor("bg-danger");
        setSnackMsg("Not Added");
        console.log(error);
        setIsLoading(false);
        getAll();
      }
    );
  };

  const SelectItem = (item) => {
    console.log(item, "item item item");
    setSelectedItem(item);
  };

  function getAll() {
    apiGetAuth(
      ENDPOINTS.EntertainmentContentAll,
      (res) => {
        setAll(res.result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <>
      {/* <Header /> */}
      {isLoading && <Loader />}
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />

      <Container className="mt-5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Update Content</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Event Name</th>
                      <th>Event Video</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{ReplaceUnderscores(item.key_name)}</td>
                          <td>
                            <form
                              onSubmit={handleSubmit}
                              className="key-update-form"
                            >
                              <input type="hidden" name="id" value={item.id} />
                              <textarea
                                disabled={
                                  item.id === selectedItem.id ? false : true
                                }
                                className="form-control text-for-item"
                                type="text"
                                name="text"
                                defaultValue={item.key_value}
                              ></textarea>
                              {item.id === selectedItem.id && (
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  save
                                </button>
                              )}
                            </form>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => SelectItem(item)}
                            >
                              edit
                            </button>
                          </td>
                          {/* getDetail */}
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UpdateKeyValues;
