import React, { useState, useRef } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Row,
  Modal,
} from "reactstrap";
import CreateVideoEvents from "components/Forms/CreateVideoEvents";
import UpdateVideoEvents from "components/Forms/UpdateVideoEvents";
import DeleteVideoEvent from "components/Forms/DeleteVideoEvent";
import { apiGetAuth } from "../../auth/APIRequests";
import { FaPlayCircle } from "react-icons/fa";
import { Player, ControlBar } from "video-react";

import Loader from "components/Global/Loader";

import { ENDPOINTS } from "../../auth/EndPoints";

const EventsVideos = () => {
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});
  const [all, setAll] = useState([]);
  const [loadUpdateModal, setLoadUpdateModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [videoModalSrc, setVideoModalSrc] = useState("");
  const playerRef = useRef(null);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleEventVideos + itemId,
      (res) => {
        setSingleItem(res.result);
        setUpdateModal(true);
        setLoadUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllEventVideos,
      (res) => {
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  };
  const playeVideo = (item) => {
    console.log(item);
    setVideoModal(true);
    setVideoModalSrc(ENDPOINTS.BaseUrl + item.videoPath);
  };

  return (
    <>
      {/* <Header /> */}
      {isLoading && <Loader />}
      <CreateVideoEvents
        showModal={showCreateModal}
        cloaseModal={() => setCreateModal(false)}
        reLoadData={() => getAll()}
      />
      {loadUpdateModal && (
        <UpdateVideoEvents
          showModalUpdate={showUpdateModal}
          reLoadData={() => getAll()}
          cloaseModal={() => setUpdateModal(false)}
          ItemDetail={singleItem}
        />
      )}

      <DeleteVideoEvent
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />

      <Container className="mt-5" fluid>
        {all.length < 3 && (
          <Row>
            <Col md={12} className="text-right mb-3">
              <button onClick={() => setCreateModal(true)} className="add-btn">
                Add Events Videos
              </button>
            </Col>
          </Row>
        )}

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Events Videos</h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Event Name</th>
                      <th>Event Video</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>{item.eventName}</td>
                          <td>
                            <button
                              className="play-btn"
                              onClick={() => playeVideo(item)}
                            >
                              <FaPlayCircle />
                            </button>
                          </td>
                          <td>
                            <button
                              className="edit-btn"
                              onClick={() => getDetail(item.id)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              className="delete-btn"
                              onClick={() => deleteEvent(item)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    {all.length < 1 && (
                      <div className="no-record"> Record Found</div>
                    )}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal className="modal-dialog-centered" isOpen={videoModal}>
        <div className="modal-body">
          <button
            aria-label="Close"
            className="close close-video"
            data-dismiss="modal"
            type="button"
            onClick={() => setVideoModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <Player ref={playerRef} autoPlay>
            <source src={videoModalSrc} />
            <ControlBar autoHide={false} />
          </Player>
        </div>
      </Modal>
    </>
  );
};

export default EventsVideos;
