import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// import NotificationPopup from './../../components/Global/NotificationPopup';
import { apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import Loader from "components/Global/Loader";
import { FaPaypal } from "react-icons/fa";

const PaypalForm = (props) => {
  React.useEffect(() => {}, []);
  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [UserId, setUserID] = useState(userId);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.target);
    for (var pair of props.formDatasPro.entries()) {
      data.append(pair[0], pair[1]);
    }
    var object = {};
    data.forEach((value, key) => (object[key] = value));
    var storeData = JSON.stringify(object);
    apiPost(
      ENDPOINTS.AddPaymentsPaypal,
      data,
      (res) => {
        localStorage.setItem("data-forms", storeData);
        window.location.href = res.result;
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };
  return (
    <React.Fragment>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="paypap-form"
        show={props.proPaypalModal}
        backdrop="static"
        keyboard={false}
        // isOpen={props.proPaypalModal}
        toggle={() => props.proPaypalModalClose()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.proPaypalModalClose()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body paypal-moadal">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <form id="appoinment-paypal" onSubmit={handleSubmit}>
            <input type="hidden" name="isTrack" value={props.isTrackPro} />
            <input type="hidden" name="amount" value={props.amountsPro} />
            <input type="hidden" name="card_holder" value="no" />
            <input type="hidden" name="card_holder_email" value="no" />
            <input type="hidden" name="user_id" value={UserId} />
            {/* <input type="hidden" name="isTrack" value={props.trackSongSrcPro} /> */}
            <Button
              className="btn-white paypal-text"
              color="default"
              type="submit"
            >
              <FaPaypal className="mr-3" />
              PAY WITH PAYPAL
            </Button>
          </form>
        </div>
      </Modal>
      {isLoading && <Loader />}
    </React.Fragment>
  );
};
PaypalForm.defaultProps = {
  formResetRequred: false,
  messageOnSucceess: "successfully",
  isTrackPro: false,
  isRegisterUserPro: false,
  trackSongSrcPro: null,
};

export default PaypalForm;
