
// reactstrap components
import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { apiGetAuth } from '../../auth/APIRequests';
import { ENDPOINTS } from '../../auth/EndPoints';
import { ConvertDateStandard } from '../../auth/DateConverions';
const DetailHeader = (props) => {
 var url =window.location.href;
 const role = localStorage.getItem('role');
 var lastPart = url.split("/").pop();
    const [singleItem, setSingleItem] = useState({});
    React.useEffect(() => {
        getDetail();
    }, []);

    const getDetail = (itemId) => {
        apiGetAuth(
          ENDPOINTS.SingleSessions + lastPart,
          (res) => {
            setSingleItem(res.result)
          },
          (error) => {
            console.log(error);
          }
        );
      }


    return (
        <>
            <div  className={ role==="1" ? 'header-change pb-8 pt-5 pt-md-8 bg-gradient-info' : 'header-change pb-8 pt-5 pt-md-8 bg-gradient-theme' }>
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row>
                            <Col lg="12" xl="12">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <Col lg="6" xl="6">
                                                <div className="session-detail-card-designs">
                                                    <h2 className="session-names ">{singleItem.name}</h2>
                                                    <h4 className="session-types mb-0">{singleItem.type}</h4>
                                                    <h4 className="session-end-dates mt-1">Start : {ConvertDateStandard(singleItem.start_date)}</h4>
                                                    <h4 className="session-end-dates mb-2">End : {ConvertDateStandard(singleItem.end_Date)}</h4>
                                                </div>
                                            </Col>

                                            <Col lg="6" xl="6">
                                            <div className="session-detail-card-designs">
                                                    <h4 className="session-end-dates ">charges : ${singleItem.session_charges}</h4>
                                                    {role==="1"?<h4 className="session-end-dates ">User limet : {singleItem.user_limet}</h4>:""}
                                                    {role==="1"?  <h4 className="session-end-dates ">Days limet : {singleItem.day_limet}</h4>:""}
                                                    <h4 className="session-end-dates ">{singleItem.description}</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default DetailHeader;
