import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CheckoutFormStripe from "components/Global/CheckoutFormStripe";
import PaypalForm from "./PaypalForm";
import { FaArrowRight } from "react-icons/fa";
import { apiGetAuth, apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { MaxDateValidation } from "../../auth/DateConverions";
import NotificationPopup from "components/Global/NotificationPopup";
import Loader from "components/Global/Loader";

const CoachingOnline = ({ selectedServicesPro }) => {
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");

  document.title = "Online Coaching";
  const role = localStorage.getItem("role");
  const userEmail = localStorage.getItem("userEmail");
  const lastName = localStorage.getItem("lastName");
  const firstName = localStorage.getItem("firstName");
  const oflineFormData = JSON.parse(localStorage.getItem("ofline-form-data"));

  const [isLoading, setIsLoading] = useState(false);
  const [allPackagesUsers, setAllPackagesUsers] = useState([]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentType, setPaymentType] = useState("stripe");
  const [serviceType, setServiceType] = useState("1");
  const [amounts, setAmounts] = useState(0);
  const [assesmets, setAssesmets] = useState(0);
  const [emails, setEmails] = useState("");
  const [formDatas, setformDatas] = useState({});
  const [paypalModal, setPaypalModal] = useState(false);
  const [oflineForm, setOflineForm] = useState(oflineFormData);

  const [profileCategories, setProfileCategories] = useState([]);
  const [packages, setPackages] = useState([]);

  React.useEffect(() => {
    getPackagesByCategoryForUser(selectedServicesPro.category_id);
  }, []);

  const getPackagesByCategoryForUser = (id) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.getPackagesByCategoryForUser + id,
      (res) => {
        setPackages(res.result);
        setAmounts(res.result[0].price);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);

        console.log(error);
      }
    );
  };

  const setPackageRates = (e) => {
    var amount =
      e.target.options[e.target.selectedIndex].getAttribute("service_amount");
    setAmounts(amount);
  };

  const resetForm = () => {
    document.getElementById("appointment-forms").reset();
  };

  const handleSubmit = async (event) => {
    let packageId = parseInt(document.getElementById("package_id").value);
    let categoryId = selectedServicesPro.category_id;

    event.preventDefault();
    var oflineForm = new FormData(event.target);

    if (role === "1" || role === "2") {
      const body = {
        source_id: packageId,
        category_id: categoryId,
      };
      apiPost(
        ENDPOINTS.OnlineCoachingOrderCheck,
        body,
        (res) => {
          if (res.result) {
            setSnack(true);
            setSnackColor("bg-danger");
            setSnackMsg(`already bought ${res.result.name} package`);
          } else {
            let asst = parseInt(document.getElementById("asst_input").value);
            setAssesmets(asst);
            var idAppontment = document.getElementById("appointment-forms");
            const data = new FormData(idAppontment);
            setformDatas(data);
            if (paymentType === "stripe") {
              localStorage.removeItem("ofline-form-data");
              setShowPaymentModal(true);
            }
            if (paymentType === "paypal") {
              localStorage.removeItem("ofline-form-data");
              setPaypalModal(true);
            }
          }
        },
        (error) => {
          console.log(error, "error");
        }
      );
    } else {
      var object = {};
      oflineForm.forEach((value, key) => (object[key] = value));
      var storeData = JSON.stringify(object);

      localStorage.setItem("ofline-form-data", storeData);
      localStorage.setItem("return_page", "/services");
      window.location.href = "/auth/login";
    }
    localStorage.setItem("additional-service", "additional_service");
  };

  return (
    <>
      <Container>
        <div className="appointment-form">
          <Form onSubmit={handleSubmit} id="appointment-forms">
            <Row>
            <Col md={12}>
              <h2 className="category-h2">{selectedServicesPro.category_name}</h2>
              </Col>
              </Row>
            <Row>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>
                    First Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First Name"
                    className="appointment-inputs"
                    name="firstName"
                    defaultValue={
                      oflineForm != null ? oflineForm.firstName : firstName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last Name"
                    className="appointment-inputs"
                    name="lastName"
                    defaultValue={
                      oflineForm != null ? oflineForm.lastName : lastName
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Formate +14155552671"
                    className="appointment-inputs"
                    name="phoneNumber"
                    defaultValue={
                      oflineForm != null ? oflineForm.phoneNumber : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    onChange={(e) => setEmails(e.target.value)}
                    placeholder="Email"
                    className="appointment-inputs"
                    name="email"
                    // defaultValue={userEmail}
                    defaultValue={
                      oflineForm != null ? oflineForm.email : userEmail
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    placeholder="Date Of birth"
                    name="dateOfBirth"
                    className="appointment-inputs"
                    max={MaxDateValidation()}
                    defaultValue={
                      oflineForm != null ? oflineForm.dateOfBirth : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select name="gender" className="appointment-inputs">
                    <option
                      value="Male"
                      selected={oflineForm?.gender === "Male" ? true : false}
                    >
                      Male
                    </option>
                    <option
                      value="Female"
                      selected={oflineForm?.gender === "Female" ? true : false}
                    >
                      Female
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Select Category"
                    className="appointment-inputs"
                    readOnly
                    defaultValue={selectedServicesPro.category_name}
                  />
                  <Form.Control
                    type="hidden"
                    name="category_id"
                    value={selectedServicesPro.category_id}
                  />
                  {/* <Form.Select
                    name="category_id"
                    id="category_id"
                    className="appointment-inputs"
                    onChange={(e) =>
                      getPackagesByCategoryForUser(e.target.value)
                    }
                  >
                    {profileCategories.length > 0 &&
                      profileCategories
                        // .filter((items) => items.status === true)
                        .map((item, index) => (
                          <option
                            selected={
                              oflineForm?.category_id === item.id ? true : false
                            }
                            price={item.price}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                  </Form.Select> */}
                  <Form.Control
                    required
                    type="hidden"
                    name="height"
                    value="0"
                  />
                  <Form.Control
                    required
                    type="hidden"
                    name="weight"
                    value="0"
                  />
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select package</Form.Label>
                  <Form.Select
                    name="package_id"
                    id="package_id"
                    className="appointment-inputs"
                    onChange={(e) => setPackageRates(e)}
                  >
                    {packages.length > 0 &&
                      packages.map((item, index) => (
                        <option
                          key={index}
                          service_amount={item.price}
                          selected={
                            oflineForm?.package_id === item.id ? true : false
                          }
                          value={item.id}
                        >
                          {item.name} | ${item.price}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control
                    required
                    type="hidden"
                    name="session_for"
                    value="bodybilding"
                  />
                  <Form.Control
                    name="payment_for"
                    type="hidden"
                    value="sessions_users"
                  />
                  <Form.Control
                    required
                    type="hidden"
                    name="height"
                    value="0"
                  />
                  <Form.Control
                    required
                    type="hidden"
                    name="weight"
                    value="0"
                  />
                  {/* <Form.Control
                    required
                    type="hidden"
                    name="amounts"
                    value={amounts}
                  /> */}
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Assessment</Form.Label>
                  <Form.Select
                    id="asst_input"
                    name="assessments"
                    className="appointment-inputs"
                  >
                    <option
                      selected={oflineForm?.assessments === "75" ? true : false}
                      value="75"
                    >
                      Basic | $75
                    </option>
                    <option
                      selected={
                        oflineForm?.assessments === "100" ? true : false
                      }
                      value="100"
                    >
                      Advanced | $100
                    </option>
                    <option
                      selected={
                        oflineForm?.assessments === "50" ? true : false
                      }
                      value="50"
                    >
                      Online Assessment  | $50
                    </option>
                      <option
                      selected={
                        oflineForm?.assessments === "0" ? true : false
                      }
                      value="0"
                    >
                      No Assessment | $0
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="position-relative mb-md-3 mb-sm-2">
                <Form.Group className="mb-3">
                  <Form.Label>Select PaymentType</Form.Label>
                  <Form.Select
                    name="payment_type"
                    className="appointment-inputs"
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option
                      value="stripe"
                      selected={
                        oflineForm?.payment_type === "stripe" ? true : false
                      }
                    >
                      Stripe
                    </option>
                    <option
                      value="paypal"
                      selected={
                        oflineForm?.payment_type === "paypal" ? true : false
                      }
                    >
                      Paypal
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} lg={12} className="position-relative text-center">
                <Form.Group className="mb-3 text-end">
                  <button
                    className="btn text-white books-appointment "
                    type="submit"
                  >
                    Book package
                    <span>
                      <FaArrowRight />
                    </span>
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />

      <CheckoutFormStripe
        showPaymentModalPro={showPaymentModal}
        amountsPro={parseInt(amounts) + parseInt(assesmets)}
        emailsPro={emails}
        paymentTypePro={paymentType}
        serviceTypePro={serviceType}
        closeModal={() => setShowPaymentModal(false)}
        resetFormPro={resetForm}
        formDatasPro={formDatas}
        messageOnSucceess="booking appoinment successfully"
      />
      <PaypalForm
        proPaypalModal={paypalModal}
        proPaypalModalClose={() => setPaypalModal(false)}
        formDatasPro={formDatas}
        amountsPro={parseInt(amounts) + parseInt(assesmets)}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default CoachingOnline;
