import React, { useState } from "react";
import { Card, CardHeader, Table, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import CreateEventPhoto from "components/Forms/CreateEventPhoto";
import DeleteAppointments from "components/Forms/DeleteAppointments";
import UpdatesAppointmentss from "components/Forms/UpdatesAppointmentss";
import Loader from "components/Global/Loader";
import { apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";
import { ConvertDateTime } from "../../auth/DateConverions";

const Appointments = () => {
  document.title = "Appointments";

  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [singleItem, setSingleItem] = useState({});
  const [all, setAll] = useState([]);

  React.useEffect(() => {
    getAll();
  }, []);

  const getDetail = (itemId) => {
    setIsLoading(true);
    apiGetAuth(
      ENDPOINTS.SingleAppointment + itemId,
      (res) => {
        setSingleItem(res.result);
        setUpdateModal(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  function getAll() {
    apiGetAuth(
      ENDPOINTS.AllAppointment,
      (res) => {
        console.log(res.Result, "all");

        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const deleteEvent = (item) => {
    setDeleteModal(true);
    setDeleteItem(item);
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <CreateEventPhoto
        showModal={showCreateModal}
        cloaseModal={() => setCreateModal(false)}
        reLoadData={() => getAll()}
      />
      <UpdatesAppointmentss
        showModalUpdate={showUpdateModal}
        reLoadData={() => getAll()}
        cloaseModal={() => setUpdateModal(false)}
        ItemDetail={singleItem}
      />
      <DeleteAppointments
        showDeleteModalPro={showDeleteModal}
        reLoadData={() => getAll()}
        closeDeleteModalPro={() => setDeleteModal(false)}
        deletePro={deleteItem}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Appointments </h3>
              </CardHeader>
              {all.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Date Of Birth</th>
                      <th>Gender</th>
                      <th>Appointment Time</th>
                      <th>Service name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all.length &&
                      all.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.book_services_admin.orderData.firstName}
                          </td>
                          <td>{item.book_services_admin.orderData.lastName}</td>
                          <td>
                            {item.book_services_admin.orderData.phoneNumber}
                          </td>
                          <td>{item.book_services_admin.orderData.email}</td>
                          <td>
                            {item.book_services_admin.orderData.dateOfBirth}
                          </td>
                          <td>{item.book_services_admin.orderData.gender}</td>
                          <td>
                            {ConvertDateTime(
                              item.book_services_admin.orderData.appointmentTime
                            )}
                          </td>
                          <td>{item.serviceName}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
              {all.length < 1 && <div className="no-record"> Record Found</div>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Appointments;
