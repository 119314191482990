import React, { useState, useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import VideosMediaCat from './VideosMediaCat';
import ImagesMediaCat from './ImagesMediaCat';
import OthderMediaCat from './OthderMediaCat';

const MediaUploadList = () => {
  
  return (
    <>
      <Tabs defaultActiveKey="profile" className="mb-3 media-tabss">
        <Tab eventKey="home" title="Videos">
          <VideosMediaCat />
        </Tab>
        <Tab eventKey="profile" title="Images">
          <ImagesMediaCat />
        </Tab>
        <Tab eventKey="contact" title="Others">
          <OthderMediaCat />
        </Tab>
      </Tabs>
    </>
  )
}

export default MediaUploadList
