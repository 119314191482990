import React from "react";
import UserHeader from "components/Headers/UserHeader.js";
import EntertainmentSlider from "../../components/Global/EntertainmentSlider";
import HomeSliderDesh from "../../components/Global/HomeSliderDesh";
import UpCommingEvent from "../../components/Global/UpCommingEvent";
import EventsPhotos from "../../components/Global/EventsPhotos";
import EventsVideos from "../../components/Global/EventsVideos";
import UpdateKeyValues from "../../components/Global/UpdateKeyValues";
import TrainPainImages from "../../components/Global/TrainPainImages";
import HealthAndBeautyAdmin from "../../components/Global/HealthAndBeautyAdmin";

const EntertainmentContent = () => {
  document.title = "Entertainment Content";

  return (
    <>
      <UserHeader />
      <div className="entertainment-content">
        <div className="entertainment-slider">
          <EntertainmentSlider />
        </div>
        <div className="entertainment-slider">
          <HomeSliderDesh />
        </div>
        <div className="entertainment-slider">
          <UpCommingEvent />
        </div>

        <div className="entertainment-slider">
          <EventsPhotos />
        </div>

        <div className="entertainment-slider">
          <EventsVideos />
        </div>

        <div className="entertainment-slider">
          <UpdateKeyValues />
        </div>

        <div className="entertainment-slider">
          <TrainPainImages />
        </div>
        <div className="entertainment-slider">
          <HealthAndBeautyAdmin />
        </div>
      </div>
    </>
  );
};

export default EntertainmentContent;
