import React, { useState } from "react";
import CreditCardInput from "react-credit-card-input";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NotificationPopup from "./NotificationPopup";
import Loader from "./Loader";
import { apiPost } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

export default function CheckoutFormStripe(props) {
  const userId = localStorage.getItem("userId");
  const [showSnack, setSnack] = useState(false);
  const [snackColor, setSnackColor] = useState("bg-primary");
  const [snackMsg, setSnackMsg] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [UserId, setUserID] = useState(userId);
  const userEmail = localStorage.getItem("userEmail");
  const lastName = localStorage.getItem("lastName");
  const firstName = localStorage.getItem("firstName");

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (expiry !== "" && cvc !== "") {
      var sentence = expiry;
      sentence = sentence.split("/");
      var month = sentence[0];
      var year = sentence[1];
      document.getElementById("card_month").value = month.trim();
      document.getElementById("card_year").value = year.trim();
      const data = new FormData(event.target);
      for (var pair of props.formDatasPro.entries()) {
        data.append(pair[0], pair[1]);
        console.log(pair[0], pair[1]);
      }
      var object = {};
      data.forEach((value, key) => (object[key] = value));
      var storeData = JSON.stringify(object);
      apiPost(
        ENDPOINTS.AddPayments,
        data,
        (res) => {
          if (props.formResetRequred) {
            props.resetFormPro();
          }
          if (res.result.status === "succeeded") {
            localStorage.setItem("data-forms", storeData);
            window.location.href =
              "/paypal-success/?paymentId=" + res.result.id;
          }
          setIsLoading(false);
        },
        (error) => {
          setSnack(true);
          setSnackColor("bg-danger");
          setSnackMsg(error.response.data.error);
          setIsLoading(false);
        }
      );
    } else {
      setSnack(true);
      setSnackColor("bg-danger");
      setSnackMsg("enter card complete card detail");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showPaymentModalPro}
        // onHide={() => setShowPaymentModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>Payment with stripe card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="cc-name">Name on card</label>
                <input
                  id="cc-name"
                  type="text"
                  className="form-control"
                  required
                  name="card_holder"
                  placeholder="Name on card"
                  defaultValue={firstName + " " + lastName}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="cc-email">Email</label>
                <input
                  id="cc-email"
                  type="email"
                  className="form-control"
                  required
                  name="card_holder_email"
                  placeholder="Email"
                  defaultValue={
                    props.emailsPro.length > 1 ? props.emailsPro : userEmail
                  }
                />
                <input
                  type="hidden"
                  name="card_year"
                  value={cardYear}
                  id="card_year"
                />
                <input
                  type="hidden"
                  name="card_month"
                  id="card_month"
                  value={cardMonth}
                />
                <input type="hidden" name="card_number" value={cardNumber} />
                <input type="hidden" name="amount" value={props.amountsPro} />
                <input type="hidden" name="user_id" value={UserId} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3 credit-cards-box">
                <CreditCardInput
                  containerClassName="box-designs-parent w-100"
                  inputClassName=""
                  fieldClassName="box-designs input"
                  cardNumberInputProps={{
                    value: cardNumber,
                    onChange: (e) => setCardNumber(e.target.value),
                  }}
                  cardExpiryInputProps={{
                    value: expiry,
                    onChange: (e) => setExpiry(e.target.value),
                  }}
                  cardCVCInputProps={{
                    value: cvc,
                    onChange: (e) => setCvc(e.target.value),
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => props.closeModal()}>
              Close
            </Button>
            <Button type="submit"> PAY ${props.amountsPro}</Button>
          </Modal.Footer>
        </form>
      </Modal>
      <NotificationPopup
        closeSnackPro={() => setSnack(false)}
        showSnkPro={showSnack}
        bgColorPro={snackColor}
        snackMsgPro={snackMsg}
      />
      {isLoading && <Loader />}
    </React.Fragment>
  );
}
CheckoutFormStripe.defaultProps = {
  formResetRequred: false,
  messageOnSucceess: "successfully",
  isTrackPro: false,
  isRegisterUserPro: false,
};
