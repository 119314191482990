import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import "react-h5-audio-player/lib/styles.css";
import CheckoutFormStripe from "../../components/Global/CheckoutFormStripe";
import PaypalForm from "../../components/Global/PaypalForm";
import Header from "../../components/Front/components/Header/Header";
import Footer from "../../components/Front/components/Footer/Footer";
import {
  Button,
  Modal
} from "reactstrap";
import { apiGet,apiGetAuth } from "../../auth/APIRequests";
import { ENDPOINTS } from "../../auth/EndPoints";

const SongList = () => {
  document.title = "Track List";
  const role = localStorage.getItem("role");
  const [all, setAll] = useState([]);
  React.useEffect(() => {
    getAll();
  }, []);

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [paypalModal, setPaypalModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [amounts, setAmounts] = useState(5);
  const [emails, setEmails] = useState("");
  const [paymentType, setPaymentType] = useState("stripe");
  const [serviceType, setServiceType] = useState("1");
  const [formDatas, setformDatas] = useState({});
  const [trackSongSrc, setTrackSongSrc] = useState(null);
  const [trackId, setTrackId] = useState(0);

  const handleTrackSongSrc = (src, id) => {
    setTrackSongSrc(src);
    setTrackId(id);
  };
  const playePouseAudio = (e, id) => {
    var audios = document.querySelectorAll(".audio");
    if (audios.length > 0) {
      for (var i = 0; i < audios.length; i++) {
        if (e.target.id !== audios[i].id) {
          audios[i].pause();
        }
      }
    }
  };
  function getAll() {
    apiGet(
      ENDPOINTS.AllTracksFront,
      (res) => {
        setAll(res.Result);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const payForDonwload = async (item, type) => {

    if (role === "1" || role === "2") {
      apiGetAuth(
        ENDPOINTS.trackOrderTrack+item.id,
        (res) => {
            // return res.Result;
           if(res.Result.length === 0){
            GoForPayment(item,type)
           }
           if(res.Result.length>0){
            setShowOrderModal(true)
           }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      localStorage.setItem("return_page", "/track-list");
      window.location.href = "/auth/login";
    }
  };

  const GoForPayment =async (item,type) => {
    await seyPaymeinType(type);
    var idAppontment = document.getElementById("tracks-form");
    await handleTrackSongSrc(
      ENDPOINTS.BaseUrl + "" + item.trackSource,
      item.id
    );
    const data = new FormData(idAppontment);
    setAmounts(item.trackPrice);
    if (type === "stripe") {
      setShowPaymentModal(true);
    } else {
      setPaypalModal(true);
    }
    setformDatas(data);  
  }
  const seyPaymeinType = (type) => {
    setPaymentType(type);
  };
  return (
    <div>
         <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-danger"
                isOpen={showOrderModal}
                toggle={() => setShowOrderModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Your attention is required
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>  setShowOrderModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="ni ni-bell-55 ni-3x" />
                        <h4 className="heading mt-4">You should read this!</h4>
                        <p>
                         you have bought this track
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                          window.location.href = "/user/tracks";
                        }}
                    >
                        go to play list
                    </Button>
                </div>
            </Modal>

      <PaypalForm
        proPaypalModal={paypalModal}
        proPaypalModalClose={() => setPaypalModal(false)}
        formDatasPro={formDatas}
        isTrackPro={true}
        amountsPro={amounts}
      />
      <Header />
      <Container fluid className="mt-5">
        <div className="songs-table table-responsive">
          <Table striped bordered hover>
            <thead className="song-headers">
              <tr>
                <th>Track Name</th>
                <th>Track</th>
                <th>Price</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>


              {all.length > 0 &&
                all.map((item, index) => (
                  <tr key={index}>
                    <td className="position-relativew">{item.trackName}</td>

                    <td className="position-relativew">
                      <audio
                        className="control-audios audio"
                        id={item.id}
                        onPlay={(e) => playePouseAudio(e, item.id)}
                        controls="controls"
                        controlsList="nodownload"
                      >
                        <source
                          src={ENDPOINTS.BaseUrl + "" + item.trackSource}
                          type="audio/mp3"
                        ></source>
                      </audio>
                    </td>
                    <td className="position-relativew">$ {item.trackPrice}</td>
                    <td>
                      <button
                        className="btn text-white bg-light-green btn-download-tracks"
                        onClick={() => payForDonwload(item, "stripe")}
                      >
                        <span>pay with Stripe</span>
                      </button>
                      <button
                        className="btn text-white bg-light-green btn-download-tracks"
                        onClick={() => payForDonwload(item, "paypal")}
                      >
                        <span>pay with paypal</span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <CheckoutFormStripe
        showPaymentModalPro={showPaymentModal}
        amountsPro={amounts}
        emailsPro={emails}
        serviceTypePro={serviceType}
        closeModal={() => setShowPaymentModal(false)}
        formDatasPro={formDatas}
      />
      <form id="tracks-form">
        <input type="hidden" name="payment_for" value="track-download" />
        <input type="hidden" name="payment_type" value={paymentType} />
        <input type="hidden" name="tracksrc" value={trackSongSrc} />
        <input type="hidden" name="track_id" value={trackId} />
      </form>
      <Footer className="mt-5" />
    </div>
  );
};

export default SongList;
