import React from "react";
import Container from "react-bootstrap/Container";
import DoYoga from "components/Global/DoYoga";
import Trainers from "components/Global/Trainers";
import HomeSlider from "components/Global/HomeSlider";
import Footer from "components/Front/components/Footer/Footer";
import Header from "components/Front/components/Header/Header";
import OverlayModal from "../../components/Global/OverlayModal";

const Home = () => {
  document.title = "Entertainment";
  return (
    <div>
      <OverlayModal />
      <Header />
      <Container fluid className="p-0 home-logos logo-back">
        <HomeSlider />
        <Trainers />
        <DoYoga />
        <Footer />
      </Container>
    </div>
  );
};

export default Home;
