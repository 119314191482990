import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import w1 from '../../assets/images/mm-4.webp';
import w2 from '../../assets/images/mm-2.webp';
import w3 from '../../assets/images/mm-3.webp';
import Zoom from 'react-reveal/Zoom';



const WelcomeLending = () => {
    return (
        <>
            <Container className="welcome-cont mb-5">
                <Row>
                    <Col md={12} lg={6} className="mb-3 position-relative">

                        <div className="from-the-moment ">
                            {/* <p className="light-green-color font-14 mb-0"></p> */}
                            <h2>About Bred Winner Fitness & Entertainment</h2>
                            <hr className="light-green-hr custom-hrs" />
                            {/* <h4 className="normal-h4s">2 Much Pain 2 Train??? BRED WINNER FITNESS… WE <span className="train-the-pains">TRAIN THE PAIN!!!</span></h4> */}
                            {/* <h4 className="normal-h4s">Bred Winner Fitness Biography</h4> */}
                            <p className="light-gary-color mt-3">At Bred Winner Fitness our motto is <span className="train-the-pains">Bred 2 Win!!!</span> We believe that success comes from within! As a result, our highly skilled Certified Trainers and Licensed Therapist also specialize in connecting with our clients to help them discover and unlock The Winner that’s inbred in us all!</p>
                            <a href="/about" className="read-more-read no-hover">Read More</a>
                        </div>

                    </Col>
                    <Col md={12} lg={6} className="mb-3 position-relative">
                        <Row>
                            <Col sm={12} md={6} lg={6} className="div-w1-box position-relative">
                                <div className="div-w1">
                                    <Zoom duration={3000} top>

                                        <img src={w1} alt="dasd" />
                                    </Zoom>

                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="mb-3 position-relative">
                                <div className="div-w2">
                                    <Zoom duration={3000} left>
                                        <img src={w2} alt="dasd" />
                                    </Zoom>
                                </div>
                                <div className="div-w3">
                                    <Zoom duration={3000} right>
                                        <img src={w3} alt="dasd" />
                                    </Zoom>
                                </div>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default WelcomeLending
