import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserVideosMediaCat from "../../components/Global/UserVideosMediaCat";
import UserOthderMediaCat from "../../components/Global/UserOthderMediaCat";
import UserImagesMediaCat from "../../components/Global/UserImagesMediaCat";
import UserHeader from "components/Headers/UserHeader.js";

const UsersVideos = () => {
  document.title = "services";

  return (
    <>
      <UserHeader />
      <div className="videos-user-tabs">
        <Tabs defaultActiveKey="Videos" className="mb-3 media-tabss">
          <Tab eventKey="home" title="Videos">
            <UserVideosMediaCat />
          </Tab>
          <Tab eventKey="profile" title="Images">
            <UserImagesMediaCat />
          </Tab>
          <Tab eventKey="contact" title="Others">
            <UserOthderMediaCat />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default UsersVideos;
