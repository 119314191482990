import React, { useState } from "react";
const OverlayModal = () => {
  const [older, setOlder] = useState(true);
  return (
    <>
      <div className={older ? "overlay-box" : "d-none"}>
        <div className="overlay-box-inner">
          <h2>Please verify you are 18 years or older to enter</h2>
          <button className="more-than-18" onClick={() => setOlder(false)}>
            i am 18 or older [Enter site]
          </button>
          <button
            className="more-under-15"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            i am under 18
          </button>
          <h4>Warning Parental Advisory & Explicit Content!</h4>
          <p>
            This website is intended for adults only and may contain content of
            an adult nature or age restricted, explicit material, which some
            viewers may find,Offensive. By entering you confirm that you are 18+
            years and are not offended by viewing such material. If you are
            under the age of 18, if such material offends you or it is illegal
            to view in your location please exit now.
          </p>
        </div>
      </div>
    </>
  );
};

export default OverlayModal;
